import React from 'react';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import {
  Button,
  FormControl,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from 'react-bootstrap';
import _upperFirst from 'lodash/upperFirst';
import { FormControlSelect } from 'rapidfab/components/formTools';
import { ASSEMBLY_META_ASSEMBLING_TYPES } from 'rapidfab/constants';
import FormRow from 'rapidfab/components/FormRow';
import { Form, Field } from 'react-final-form';
import { printerTechnologies } from 'rapidfab/utils/printerConfiguration';
import { FormattedMessage } from 'rapidfab/i18n';
import { finalFormInputTypes } from 'rapidfab/types';
import _uniq from 'lodash/uniq';

const AnatomicalModelAssemblyModal = ({
  onClose,
  isLoading,
  isSubmitting,
  onFormSubmit,
  initialFormValues,
}) => {
  const disabledActions = isLoading || isSubmitting;
  return (
    <Modal show onHide={() => onClose()} backdrop="static">
      <Form
        onSubmit={onFormSubmit}
        initialValues={initialFormValues}
        validate={values => {
          const errors = {};
          const re = /["&*/:<>?\\_|]/g;
          if (values.name) {
            const matches = [...values.name.matchAll(re)].flatMap(match => match);
            if (matches.length) {
              errors.name = `Invalid character(s): ${_uniq(matches).join(', ')}`;
            }
          }
          return errors;
        }}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader closeButton>
              <ModalTitle>
                {initialFormValues?.uuid ? 'Edit' : 'Create'} Assembly
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              {
                isLoading
                  ? <Loading />
                  : (
                    <>
                      <FormRow id="field.name" defaultMessage="Name" isRequired>
                        <Field
                          name="name"
                          render={props => (
                            <FormControl
                              required
                              {...props.input}
                            />
                          )}
                        />
                      </FormRow>
                      <FormRow id="field.type" defaultMessage="Type">
                        <Field
                          name="assembly_type"
                          render={props => (
                            <FormControlSelect
                              id="assemblyTypeSelect"
                              required
                              {...props.input}
                            >
                              {Object.values(ASSEMBLY_META_ASSEMBLING_TYPES).map(type => (
                                <option value={type} key={type}>{_upperFirst(type)}</option>
                              ))}
                            </FormControlSelect>
                          )}
                        />
                      </FormRow>

                      <FormRow defaultMessage="Printing Technology" isRequired>
                        <Field
                          name="printing_tech"
                          render={props => (
                            <FormControlSelect
                              id="printingTechnologySelect"
                              required
                              {...props.input}
                            >
                              {!values.printing_tech && (
                                <FormattedMessage id="field.choose" defaultMessage="Choose…">{text =>
                                  <option value="" disabled>{text}</option>}
                                </FormattedMessage>
                              )}
                              {printerTechnologies()
                                .map(process => (
                                  <option value={process} key={process}>{process}</option>
                                ))}
                            </FormControlSelect>
                          )}
                        />
                      </FormRow>
                    </>
                  )
              }
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                variant="success"
                disabled={disabledActions}
              >
                { isSubmitting ? <Loading /> : 'Save' }
              </Button>
              <Button variant="primary" disabled={isSubmitting} onClick={() => onClose()}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        )}
      />
    </Modal>
  );
};

AnatomicalModelAssemblyModal.defaultProps = {
  isSubmitting: false,
  isLoading: false,
};

AnatomicalModelAssemblyModal.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  isLoading: PropTypes.bool,
  initialFormValues: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    assembly_type: PropTypes.string,
    printing_tech: PropTypes.string,
  }).isRequired,
  input: finalFormInputTypes.isRequired,
};

export default AnatomicalModelAssemblyModal;
