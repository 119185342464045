import React from 'react';
import { AnatomicalModels } from 'rapidfab/components/AnatomicalModel';
import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';
import { getAnatomicalModels } from 'rapidfab/selectors';

const AnatomicalModelsContainer = componentProps => (
  <AnatomicalModels
    {...componentProps}
  />
);

export default withRecordsListHandling(
  AnatomicalModelsContainer,
  getAnatomicalModels,
  ['anatomical-model'],
  { useLocationFiltering: false },
);
