import React from 'react';
import hexRgb from 'hex-rgb';
import { SketchPicker } from 'react-color';
import { reduxFormFieldType } from 'rapidfab/types';

const ColorPicker = ({
  colorField,
  ...otherProps
}) => {
  let rgbaValue = '';
  if (colorField.value) {
    // convert hex color value to { r, g, b }
    const { red: r, green: g, blue: b } = hexRgb(colorField.value);
    // rgbaValue is what's passed into color picker
    rgbaValue = { r, g, b };
  }

  return (
    <SketchPicker
      disableAlpha
      presetColors={[]}
      color={rgbaValue}
      onChangeComplete={({ hex }) => {
        // color field stored as hex in color field
        colorField.onChange(hex === 'transparent' ? '#000000' : hex);
      }}
      {...otherProps}
    />
  );
};

ColorPicker.propTypes = {
  colorField: reduxFormFieldType,
  opacityField: reduxFormFieldType,
};

ColorPicker.defaultProps = {
  colorField: {
    value: null,
    onChange: () => {},
  },
  opacityField: {
    value: null,
    onChange: () => {},
  },
};

export default ColorPicker;
