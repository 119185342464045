import { PRINTER_CONFIGURATION_JSON } from 'rapidfab/constants/anatomicalModel';
import _uniq from 'lodash/uniq';

// Type definitions

/** @typedef {typeof PRINTER_CONFIGURATION_JSON.printerTechnology[number]} PrinterTech */

/**
 * @returns {string[]}
*/
export const printerTechnologies = () => PRINTER_CONFIGURATION_JSON.printerTechnology.map(tech => tech.name);

/**
 * @param name {string}
 * @returns {PrinterTech | undefined}
*/
export const getPrinterTechnology = name =>
  PRINTER_CONFIGURATION_JSON.printerTechnology.find(tech => tech.name === name);

/**
* @param {PrinterTech | undefined} tech
* @param {object} values
*/
export const getPrinterOptions = (tech, values) => {
  const families = tech?.anatomy?.family;
  const selectedFamily = values.printer_anatomy_family;

  const elements = families?.find(family => family.name === selectedFamily)?.element;
  const selectedElement = values.printer_anatomy_element;

  const propertyNames = elements?.find(element => element.name === selectedElement)?.property;
  const selectedPropertyName = values.printer_anatomy_property_name;

  const propertyValues = propertyNames?.find(
    property => property.name === selectedPropertyName,
  )?.value;

  // If anatomy is null, property values will be null. In that case, look at
  // printer tech's color array.

  let techColors = [];
  if (tech?.color) {
    techColors = _uniq(tech.color.map(color => color.toLowerCase()));
  }
  const colors = propertyValues?.find(
    value => value.name === values.printer_anatomy_property_value,
  )?.color || techColors || [];

  return {
    families,
    elements,
    propertyNames,
    propertyValues,
    rgb: !!tech?.rgb,
    colors,
  };
};

export const isPartValid = (techName, part) => {
  const tech = getPrinterTechnology(techName);
  if (!tech) return true;

  // If color array, must belong to it
  if (tech.color.length && !tech.color.some(
    color => `#${color.toLowerCase().slice(0, 6)}` === part.color.toLowerCase(),
  )) {
    return false;
  }

  // Check flexibility values
  if (!tech.flexibility.includes(`${part.flexibility}`)) {
    return false;
  }

  // Check printer anatomy fields
  if ((tech.anatomy?.family || part.printer_anatomy_family !== 'none') &&
    !(tech.anatomy?.family ?? []).some(family => family.name === part.printer_anatomy_family)) {
    return false;
  }

  return true;
};
