import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Container, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import Fa from 'react-fontawesome';
import truncateText from 'rapidfab/utils/truncateText';
import Loading from 'rapidfab/components/Loading';

import { FormattedDateTime, FormattedMessage } from 'rapidfab/i18n';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';

import ModelThumbnail from 'rapidfab/components/ModelThumbnail';
import Feature from 'rapidfab/components/Feature';
import ReadOnlyComments from 'rapidfab/components/comments/ReadOnlyComments';
import { PIECE_STATUS_MAP } from 'rapidfab/mappings';
import { FEATURES, LINE_ITEM_COMPOSITION_TYPES, ROUTES } from 'rapidfab/constants';
import extractUuid from 'rapidfab/utils/extractUuid';
import getShortUUID from 'rapidfab/utils/getShortUUID';
import PieceEditNameModal from 'rapidfab/components/records/piece/PieceEditNameModal';
import { Link } from 'react-router-dom';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import NebumindContainer from 'rapidfab/containers/records/NebumindContainer';
import Config from 'rapidfab/config';
import WorkPlanViewModalContainer from 'rapidfab/containers/records/order/WorkPlanViewModalContainer';
import { modelResourceType, orderResourceType, pieceResourceType, workflowResourceType } from 'rapidfab/types';
import TraceabilityReport from './TraceabilityReport';
import AllWorkflowsSteps from './AllWorkflowsSteps';

const SpaceBetweenText = ({
  left,
  right,
}) => (
  <div className="clearfix">
    <div className="pull-left">{left}</div>
    <div className="pull-right wrap-text">{right}</div>
  </div>
);

SpaceBetweenText.propTypes = {
  left: PropTypes.element.isRequired,
  right: PropTypes.element.isRequired,
};

const PanelHeader = ({ notesCollapsed, setNotesCollapsed, notes }) => {
  const onClickCollapse = () => setNotesCollapsed(!notesCollapsed);
  return (
    <span
      className="panel-header"
    >
      {notes ? (
        <p className="text-right">
          {!notesCollapsed ? (
            <>
              {truncateText(notes, 15)}
              <b className="spacer-right spacer-left">(read more)</b>
            </>
          ) : ''}
          <Fa onClick={onClickCollapse} tabIndex={0} role="button" className="text-white" name={notesCollapsed ? 'chevron-up' : 'chevron-down'} />
        </p>
      ) : <p className="text-right">N/A</p>}
      {notesCollapsed && <p>{notes}</p>}
    </span>
  );
};

PanelHeader.propTypes = {
  notesCollapsed: PropTypes.bool.isRequired,
  setNotesCollapsed: PropTypes.func.isRequired,
  notes: PropTypes.string.isRequired,
};

const PieceComponent = ({
  comments,
  piece,
  order,
  lineItem,
  model,
  materialBatch,
  workflow,
  scrollToTraceabilityReport,
  initialFilterByCADReplace,
  onPieceUpdate,
  piecesByUri,
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const [notesCollapsed, setNotesCollapsed] = useState(false);
  const [showEditPieceNameModal, setShowEditPieceNameModal] = useState(false);
  const [showWorkPlanModal, setShowWorkPlanModal] = useState(false);
  // Ternary operator to prevent errors for the case when piece is null
  const breadcrumbs = piece ? ['pieces', `${piece.name} (${getShortUUID(piece.uuid)})`] : [];

  const remanufacturedPieceRelatedToCurrentPiece = piecesByUri[piece?.remanufactured_to] || null;

  const { status, notes } = piece;
  const { created } = lineItem || {};
  const {
    name,
    uuid,
  } = order || {};

  const remanufacturedUUID = extractUuid(piece?.remanufactured_to);

  const WorkflowName = () => {
    if (!piece.workflow) {
      return (
        <FormattedMessage
          id="field.none"
          defaultMessage="None"
        />
      );
    }

    if (!workflow) {
      // Is not loaded yet
      return (<FormattedMessage id="notAvailable" defaultMessage="N/A" />);
    }

    return workflow.name;
  };

  const isAssembly = lineItem && lineItem.composition_type === LINE_ITEM_COMPOSITION_TYPES.ASSEMBLY;
  const isCoPrint = lineItem && lineItem.composition_type === LINE_ITEM_COMPOSITION_TYPES.CO_PRINT;

  const noModelPiece = isAssembly || isCoPrint;

  return (
    <>
      <Container>
        <BreadcrumbNav breadcrumbs={breadcrumbs} />
        <hr />
        <Card bg="dark" className="m-b">
          <Card.Header className="pd-exp inverse">
            <div className="d-flex align-items-center justify-content-between">
              <p>Piece Summary</p>
              {
                piece.current_print ? (
                  <Feature featureName={FEATURES.QR_PRINTS_TRAVELER}>
                    <a
                      href={`${Config.HOST.QR}/traveler/print/${
                        extractUuid(piece.current_print)
                      }`}
                      target="_blank"
                      className="btn btn-info-outline"
                      rel="noopener noreferrer"
                    >
                      <Fa name="qrcode" size="lg" className="spacer-right" />
                      Piece QR Code
                    </a>
                  </Feature>
                ) : null
              }

            </div>

          </Card.Header>
          <div className="card-body-wrapper">
            <Card.Body>
              <Row>
                <Col xs={6}>
                  {model && (
                    <ModelThumbnail snapshot={model.snapshot_content} />
                  )}
                  {noModelPiece && (
                    <span>No image available, please add an image of Assembled Model to the report.</span>
                  )}
                </Col>
                <Col xs={6}>
                  <ListGroup className="m-b">
                    <ListGroupItem>
                      <SpaceBetweenText
                        left={(
                          <b>
                            <FormattedMessage
                              id="field.name"
                              defaultMessage="Name"
                            />:
                          </b>
                        )}
                        right={(
                          <>
                            {piece.name}
                            <Button
                              className="pull-right spacer-left"
                              variant="primary"
                              size="xs"
                              tabIndex={0}
                              onClick={() => {
                                setShowEditPieceNameModal(true);
                              }}
                            >
                              <Fa name="pencil" />
                            </Button>
                          </>
                        )}
                      />
                    </ListGroupItem>
                    <ListGroupItem>
                      <SpaceBetweenText
                        left={(
                          <b>
                            <FormattedMessage
                              id="field.order"
                              defaultMessage="Order"
                            />:
                          </b>
                        )}
                        right={(
                          <Link to={getRouteURI(ROUTES.ORDER_EDIT, { uuid }, {}, true)}>{name}</Link>
                        )}
                      />
                    </ListGroupItem>
                    <ListGroupItem>
                      <SpaceBetweenText
                        left={(
                          <b>
                            <FormattedMessage
                              id="field.status"
                              defaultMessage="Status"
                            />:
                          </b>
                        )}
                        right={(
                          <FormattedMessage
                            id={PIECE_STATUS_MAP[status].id}
                            defaultMessage={PIECE_STATUS_MAP[status].defaultMessage}
                          />
                        )}
                      />
                    </ListGroupItem>
                    {remanufacturedUUID && (
                      <ListGroupItem>
                        <SpaceBetweenText
                          left={(
                            <b>
                              <FormattedMessage
                                id="field.remanufactured_to"
                                defaultMessage="Remanufactured To"
                              />:
                            </b>
                          )}
                          right={(
                            <Link
                              to={getRouteURI(ROUTES.PIECE_EDIT,
                                { uuid: remanufacturedUUID }, {}, true)}
                            >
                              {remanufacturedPieceRelatedToCurrentPiece ?
                                `${remanufacturedPieceRelatedToCurrentPiece?.name} - (${getShortUUID(remanufacturedPieceRelatedToCurrentPiece?.uuid)})` : <Loading />}
                            </Link>
                          )}
                        />
                      </ListGroupItem>
                    )}
                    <ListGroupItem>
                      <SpaceBetweenText
                        left={(
                          <b>
                            <FormattedMessage
                              id="workflow"
                              defaultMessage="Production Workflow"
                            />:
                          </b>
                        )}
                        right={(
                          <>
                            <WorkflowName />
                            <Button
                              className="pull-right spacer-left"
                              variant="primary"
                              size="xs"
                              tabIndex={0}
                              onClick={() => setShowWorkPlanModal(true)}
                              disabled={!workflow && !lineItem.workflow}
                            >
                              <Fa name="eye" />
                            </Button>
                          </>
                        )}
                      />
                    </ListGroupItem>
                    {created && (
                      <ListGroupItem>
                        <SpaceBetweenText
                          left={(
                            <b>
                              <FormattedMessage
                                id="created"
                                defaultMessage="Created"
                              />:
                            </b>
                          )}
                          right={<FormattedDateTime value={created} />}
                        />
                      </ListGroupItem>
                    )}
                    <ListGroupItem>
                      <SpaceBetweenText
                        left={(
                          <b>
                            <FormattedMessage
                              id="notes"
                              defaultMessage="Notes"
                            />:
                          </b>
                        )}
                        right={notes?.length < 30 ? <p>{notes}</p> : (
                          <PanelHeader
                            notes={notes}
                            notesCollapsed={notesCollapsed}
                            setNotesCollapsed={setNotesCollapsed}
                          />
                        )}
                      />
                    </ListGroupItem>
                  </ListGroup>

                  {materialBatch && (
                    <Feature featureName={FEATURES.MATERIAL_MANAGEMENT}>
                      <ListGroup>
                        <ListGroupItem>
                          <div className="clearfix">
                            <div className="pull-left">
                              <Button
                                size="xs"
                                onClick={() => {
                                  setCollapsed(!collapsed);
                                }}
                              >
                                <Fa
                                  name={collapsed ? 'chevron-right' : 'chevron-down'}
                                />
                              </Button>
                              &nbsp;
                              <span>
                                <FormattedMessage
                                  id="field.material"
                                  defaultMessage="Material"
                                />:
                              </span>
                            </div>
                            <div className="pull-right">
                              <Link to={getRouteURI(ROUTES.MATERIAL_BATCH,
                                { uuid: extractUuid(materialBatch.uri) }, {}, true)}
                              >
                                {/* TODO: Change when UI for multiple lots is ready {materialBatch.material_name} */}
                                {materialBatch.materials?.map(material => material.name)
                                  .join(', ')} - Batch {getShortUUID(materialBatch.uri)}
                              </Link>
                            </div>
                          </div>
                        </ListGroupItem>
                        {!collapsed && (
                          <>
                            <ListGroupItem>
                              <SpaceBetweenText
                                left={(
                                  <FormattedMessage
                                    id="materialBatch.usageCycles"
                                    defaultMessage="Usage Cycles"
                                  />
                                )}
                                right={materialBatch.usage_cycles}
                              />
                            </ListGroupItem>
                          </>
                        )}
                      </ListGroup>
                    </Feature>
                  )}

                  <AllWorkflowsSteps
                    piece={piece}
                  />
                </Col>
              </Row>
            </Card.Body>
          </div>
        </Card>

        <Feature featureName={FEATURES.NEBUMIND}>
          {
            piece.external_print_analysis &&
            <NebumindContainer uuid={piece.external_print_analysis.uuid} />
          }
        </Feature>

        {
          comments?.length ? (
            <ReadOnlyComments
              comments={comments}
            />
          ) : null
        }

        <TraceabilityReport
          initialFilterByCADReplace={initialFilterByCADReplace}
          scrollToTraceabilityReport={scrollToTraceabilityReport}
        />
      </Container>
      {showEditPieceNameModal && (
        <PieceEditNameModal
          onUpdate={onPieceUpdate}
          piece={piece}
          onClose={() => setShowEditPieceNameModal(false)}
        />
      )}
      {showWorkPlanModal && (
        <WorkPlanViewModalContainer
          onClose={() => setShowWorkPlanModal(false)}
          previewOnly
          lineItemUri={lineItem.uri}
          workflowUri={workflow ? workflow.uri : lineItem.workflow}
        />
      )}
    </>
  );
};

PieceComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  comments: PropTypes.arrayOf(PropTypes.object).isRequired,
  piece: pieceResourceType.isRequired,
  workflow: workflowResourceType,
  order: orderResourceType,
  lineItem: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    workflow: PropTypes.string.isRequired,
    composition_type: PropTypes.string.isRequired,
  }),
  model: modelResourceType,
  piecesByUri: PropTypes.shape({}).isRequired,
  materialBatch: PropTypes.shape({
    uri: PropTypes.string,
    // material_name: PropTypes.string,
    materials: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
    usage_cycles: PropTypes.number,
  }),
  scrollToTraceabilityReport: PropTypes.bool.isRequired,
  initialFilterByCADReplace: PropTypes.bool.isRequired,
  onPieceUpdate: PropTypes.func.isRequired,
};

PieceComponent.defaultProps = {
  materialBatch: null,
  model: null,
  lineItem: null,
  workflow: null,
  order: null,
};

export default PieceComponent;
