import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Badge,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import Fa from 'react-fontawesome';

import RCTooltip from 'rc-tooltip';

import {
  FormattedMessage,
  FormattedVolume,
  FormattedMessageMappingOption,
} from 'rapidfab/i18n';
import Config from 'rapidfab/config';
import { extractUuid } from 'rapidfab/reducers/makeApiReducers';
import { LINE_ITEM_STATUS_MAP } from 'rapidfab/mappings';
import {
  LINE_ITEM_STATUS,
  DESIGN_REVIEW_MATERIAL_NAME,
  FEATURES,
  WORKFLOW_TYPES,
  WORKFLOW_USAGE_STATES,
  MODEL_LAYER_THICKNESS_SETTINGS,
  MATERIAL_UNITS,
  LINE_ITEM_DESIGN_STAGE_STATUSES,
  BEEHIVE_LINE_ITEM_CUSTOM_FIELDS, ROUTES, LINE_ITEM_COMPOSITION_TYPES,
} from 'rapidfab/constants';
import { LINE_ITEM_STATUS_TRANSFORMATIONS } from 'rapidfab/transformations';
import getLineItemFormOptions from 'rapidfab/utils/lineItemFormOptions';

import _reject from 'lodash/reject';
import _find from 'lodash/find';
import Feature from 'rapidfab/components/Feature';
import FormRow from 'rapidfab/components/FormRow';
import VisibleFor from 'rapidfab/components/VisibleFor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import WorkPlanViewModalContainer from 'rapidfab/containers/records/order/WorkPlanViewModalContainer';
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import ModelMeshQuality from 'rapidfab/components/records/order/edit/ModelMeshQuality';
import CustomFieldList from 'rapidfab/components/forms/CustomFieldList';
import CustomFieldComponent from 'rapidfab/components/forms/CustomFieldComponent';
import SelectSingle from 'rapidfab/components/forms/SelectSingle';
import getlineItemCustomFieldReferencesOverrides from 'rapidfab/utils/lineItemCustomFieldReferencesOverrides';
import getVisibleCustomFieldReferencesWithOptions from 'rapidfab/utils/getVisibleCustomFieldReferencesWithOptions';
import FormattedLocalizedCost from 'rapidfab/components/FormattedLocalizedCost';
import DeleteChecklistsConfirmationModal from 'rapidfab/components/modals/DeleteChecklistsConfirmationModal';
import {
  assemblyPartMetaResourceType,
  lineItemResourceType,
  modelResourceType,
  orderResourceType,
} from 'rapidfab/types';
import Tooltip from 'rapidfab/components/Tooltip';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import SelectSingleLazy from 'rapidfab/components/forms/SelectSingleLazy';
import ModelLibraryContainer from 'rapidfab/containers/records/ModelLibraryContainer';
import Loading from 'rapidfab/components/Loading';
import SaveDropdownButton from './SaveDropdownButton';
import LineItemDuplicateModal from './LineItemDuplicateModal';
import FileInput from './FileInput';
import AssemblyPartSettingsField from './LineItem/AssemblyPartSettingsField';
import AssemblyPartMetaContextTooltip from './LineItem/AssemblyPartMetaContextTooltip';

const MODALS = {
  WORKFLOW_CHANGE_CONFIRMATION: 'workflow-change-confirmation',
  VIEW_WORK_PLAN: 'view-work-plan',
  LINE_ITEM_DUPLICATE: 'line-item-duplicate',
};

const ResourceLink = ({ href }) => {
  const attributes = {};

  if (href.startsWith('http')) {
    attributes.target = '_blank';
    attributes.rel = 'noopener noreferrer';
  }

  return (
    <a href={href} {...attributes}>
      Go <Fa name="external-link" />
    </a>
  );
};

ResourceLink.propTypes = { href: PropTypes.string.isRequired };

const LineItemEditForm = ({
  autorun,
  customFieldValues,
  customLineItemFieldReferences,
  customOrderFieldReferences,
  handleCloseCompleteWarningModal,
  handleCloseRecalculationLineItemModal,
  handleInputChange,
  handleLayerThicknessBlur,
  handleModelDownload,
  isModelUploading,
  isLineItemSubmitting,
  isLineItemDuplicating,
  layerThickness,
  lineItem,
  partName,
  customerId,
  modalTextPending,
  model,
  modelLibraries,
  notes,
  baseMaterial,
  baseMaterialColor,
  baseMaterialUrl,
  baseMaterials,
  baseMaterialsByLocationUri,
  noModelUpload,
  onSubmit,
  onDuplicate,
  onMeshHealRepair,
  onReloadPrints,
  onReloadChecklistLinkings,
  onDelete,
  onCustomFieldChange,
  quantity,
  discountByQuantity,
  designHours,
  showCompleteWarningModal,
  showRecalculationLineItemModal,
  submitToModelLibrary,
  submitRecalculatePriceModal,
  submitLeavePriceModal,
  status,
  submitConfirmModal,
  supportMaterial,
  supportMaterialColor,
  supportMaterialUrl,
  supportMaterials,
  workflow,
  workflows,
  workChecklistLinkings,
  infillStrategies,
  supportStrategies,
  supportStrategy,
  infillStrategy,
  readOnly,
  isDebugModeEnabled,
  isCurrentUserRestricted,
  isOrderBusinessSegmentFeatureEnabled,
  isBoeingOrderFieldsFeatureEnabled,
  is3MOrderFieldsFeatureEnabled,
  isUserRestricted,
  order,
  isCADToSTLConversionFeatureEnabled,
  modelUpload,
  handleFileRemove,
  handleFileChange,
  onFetchMoreWorkflows,
  comments,
  documents,
  modelLibraryModal,
  isGuidelineEngineFeatureEnabled,
  guidelineSuggestionsForLineItem,
  fetchingGuidelineSuggestionsForLineItem,
  fetchingProcessSteps,
  assemblyPartMeta,
  features,
  anyPiecesHaveModifiedWorkflow,
  isAdditiveWorkflowFeatureEnabled,
  isPowderWorkflowFeatureEnabled,
  workflowTypeKey,
  workflowTypeEstimatesKey,
  isPiecesOrWorkflowfetching,
}) => {
  const [visibleModal, setVisibleModal] = useState(null);

  const isReadOnly = typeof readOnly === 'function' ? readOnly(lineItem) : readOnly;
  const currentModelLibrary = modelLibraries[lineItem[workflowTypeKey]?.model];
  const statusOptions = LINE_ITEM_STATUS_TRANSFORMATIONS[lineItem.status];

  const workInstructionsExist = workChecklistLinkings.length > 0;

  const { modelLibraryModalVisible, setModelLibraryModalVisible } = modelLibraryModal;

  const nonSpecimenWorkflows = _reject(workflows, ['type', WORKFLOW_TYPES.SPECIMEN]);
  const usedWorkflow = _find(workflows, { uri: lineItem.workflow });
  const isCustomWorkflow = usedWorkflow && usedWorkflow.line_item !== null;

  const isDesignStage = LINE_ITEM_DESIGN_STAGE_STATUSES.includes(lineItem.status);

  const is3MUser = is3MOrderFieldsFeatureEnabled;

  const commentsForModelLibrary = comments
    .filter(({ related_uuid }) => related_uuid === currentModelLibrary?.uuid);

  const documentsForModelLibrary = documents
    .filter(({ related_uuid }) => related_uuid === currentModelLibrary?.uuid);

  const totalCommentsAndDocuments = commentsForModelLibrary?.length + documentsForModelLibrary?.length;

  const onCloseWorkPlanViewModal = () => {
    onReloadPrints(order.uri);
    onReloadChecklistLinkings();
    setVisibleModal(null);
  };

  const onCloseDuplicateModal = () => setVisibleModal(null);

  const onDeleteChecklistsAndSubmit = () => {
    onSubmit();
    setVisibleModal(null);
  };

  const handleSubmit = event => {
    event.preventDefault();
    // When workflow was changed, and we have some custom checklists added - warn user
    if (workflow !== lineItem.workflow && workChecklistLinkings.length > 0) {
      setVisibleModal(MODALS.WORKFLOW_CHANGE_CONFIRMATION);
      return;
    }

    onSubmit();
  };

  const handleRenderSaveButton = () => {
    if (isReadOnly || (isUserRestricted && lineItem?.status === LINE_ITEM_STATUS.CONFIRMED)) {
      return null;
    }
    const showDeleteAction = lineItem.composition_type === LINE_ITEM_COMPOSITION_TYPES.SINGLE_MESH_PRODUCT;
    return (
      <SaveDropdownButton
        onSubmit={handleSubmit}
        onDelete={showDeleteAction && onDelete}
        onDuplicate={onDuplicate}
        resourceName="CAD File"
        disabled={isLineItemSubmitting || isModelUploading || isLineItemDuplicating}
      />
    );
  };

  const workflowTypeInitialValue = useMemo(() => {
    if (isAdditiveWorkflowFeatureEnabled) return 'additiveWorkflow';
    if (isPowderWorkflowFeatureEnabled) return 'powderWorkflow';
    return null;
  }, [isPowderWorkflowFeatureEnabled, isAdditiveWorkflowFeatureEnabled]);

  const options = getLineItemFormOptions({
    features,
    isReadOnly,
    isNormalUser: !isCurrentUserRestricted,
    isDanfossUser: isOrderBusinessSegmentFeatureEnabled,
    isBoeingUser: isBoeingOrderFieldsFeatureEnabled,
    is3MUser: is3MOrderFieldsFeatureEnabled,
    workInstructionsExist,
    isCustomWorkflow,
    compositionType: lineItem.composition_type,
  });

  const showMeshQuality = options.printable.show && !is3MUser;

  const lineItemCustomFieldReferencesOverrides = getlineItemCustomFieldReferencesOverrides({
    isNormalUser: !isUserRestricted,
  });

  const visiblelineItemCustomFieldReferencesWithOptions = getVisibleCustomFieldReferencesWithOptions({
    fieldReferences: customLineItemFieldReferences,
    fieldValues: customFieldValues,
    fieldOverrides: lineItemCustomFieldReferencesOverrides,
    parentFieldReferences: customOrderFieldReferences,
    parentFieldValues: order.custom_field_values,
  });

  const revisionFieldReference = _find(
    customLineItemFieldReferences,
    { field_id: BEEHIVE_LINE_ITEM_CUSTOM_FIELDS.REVISION },
  );
  const revisionFieldValue = _find(
    customFieldValues,
    ({ custom_field }) => revisionFieldReference && custom_field === revisionFieldReference.uri,
  );

  // when model version number is clicked, user will be routed to the traceability
  // report for the first piece in the `pieces` list with the CAD Model Replaced
  // filter applied
  // const pieceUuidToRouteTo = pieces[0] && extractUuid(pieces[0].uri);

  const discountOverlay = (
    <Popover id="popover-trigger-focus">
      <Popover.Header>Discounts</Popover.Header>
      <Popover.Body>
        <ul>
          {options.discountByQuantity.overlay.map(discount => (
            <li key={discount.quantity} style={{ listStyle: 'none' }}>
              {discount.quantity} items: {discount.percentage}%
            </li>
          ))}
        </ul>
      </Popover.Body>
    </Popover>
  );

  // Get current baseMaterial Name
  const currentBaseMaterial = baseMaterials.find(({ uri }) => uri === baseMaterial);
  const baseMaterialName = currentBaseMaterial && currentBaseMaterial.name;
  let bureauCost;
  let materialUsed;
  if (lineItem.estimates && lineItem.estimates.total_cost) {
    bureauCost =
      (
        <FormattedLocalizedCost
          value={lineItem.estimates.total_cost}
        />
      );
  } else if (lineItem.estimates && lineItem[workflowTypeEstimatesKey]?.printing_time === null) {
    bureauCost =
      (
        <div>
          <Fa name="spinner" spin />
          <span style={{ marginRight: '0.5em' }} />
          Calculating…
        </div>
      );
  } else {
    bureauCost = <FormattedMessage id="notAvailable" defaultMessage="N/A" />;
  }

  let baseMaterialOptions = order.location
    ? (
      // Use materials from location if there are any
      baseMaterialsByLocationUri[order.location]
      // or no materials as a fallback
      || []
    )
    : baseMaterials;
  if (currentBaseMaterial && !_find(baseMaterialOptions, { uri: currentBaseMaterial.uri })) {
    baseMaterialOptions = [currentBaseMaterial, ...baseMaterialOptions];
  }

  if (model && model.volume_mm && baseMaterialName !== DESIGN_REVIEW_MATERIAL_NAME) {
    materialUsed =
      (
        <FormattedVolume
          value={model.volume_mm * quantity}
          valueUnits={MATERIAL_UNITS.MM3}
          maximumFractionDigits={2}
        />
      );
  } else if (
    baseMaterialName !== DESIGN_REVIEW_MATERIAL_NAME
    && lineItem.estimates
    && lineItem[workflowTypeEstimatesKey]?.printing_time === null
  ) {
    materialUsed =
      (
        <div>
          <Fa name="spinner" spin />
          <span style={{ marginRight: '0.5em' }} />
          Calculating…
        </div>
      );
  } else {
    materialUsed = <FormattedMessage id="notAvailable" defaultMessage="N/A" />;
  }

  const showPartSettingsField =
    assemblyPartMeta && lineItem.composition_type === LINE_ITEM_COMPOSITION_TYPES.ASSEMBLY_PART;

  return (
    <Form horizontal>
      {currentModelLibrary &&
      modelLibraryModalVisible && (
        <ModelLibraryContainer
          uuid={currentModelLibrary?.uuid}
          handleClose={() => setModelLibraryModalVisible(false)}
          isShowing={modelLibraryModalVisible}
          renderAsOverlay
        />
      )}
      <div className="line-item-btn-row">
        {!lineItem[workflowTypeKey]?.no_model_upload && (
          <VisibleFor unrestricted>
            <Feature featureName={FEATURES.SPECIMEN_LIBRARY}>
              <span>
                {
                  currentModelLibrary ? (
                    <Tooltip
                      placement="bottom"
                      trigger={(
                        <Button
                          variant="primary"
                          size="sm"
                          className="mr15"
                          onClick={() =>
                            submitToModelLibrary(model, currentModelLibrary)}
                        >
                          <div className="d-flex align-items-center">
                            <p className="m-a-0">Model Library Details</p>
                            <Fa
                              className="spacer-left"
                              name="eye"
                            />
                          </div>
                        </Button>
                      )}
                    >
                      {
                        commentsForModelLibrary &&
                    documentsForModelLibrary &&
                    currentModelLibrary ?
                          (
                            <div>
                              <p className="d-flex align-items-center">
                                <Fa name="folder" className="spacer-right" />Documents:&nbsp;
                                {documentsForModelLibrary?.length}
                              </p>
                              <p className="d-flex align-items-center">
                                <Fa name="comment" className="spacer-right" />Comments:&nbsp;
                                {commentsForModelLibrary?.length}
                              </p>
                            </div>
                          ) : <Loading />
                      }
                    </Tooltip>
                  )
                    : (
                      <Button
                        variant="primary"
                        size="sm"
                        className="mr15"
                        onClick={() =>
                          submitToModelLibrary(model, currentModelLibrary)}
                      >
                        <div className="d-flex align-items-center">
                          <FormattedMessage
                            id="record.addToModelLibrary"
                            defaultMessage="Add to Model Library"
                          />
                          <Fa
                            className="spacer-left"
                            name="plus"
                          />
                        </div>
                      </Button>
                    )
                }
                {totalCommentsAndDocuments > 0 && (
                  <Badge
                    style={{ position: 'relative', right: 25, bottom: 15, margin: 0 }}
                    className="badge badge-sm text-white rounded-circle"
                    bg="danger"
                  >
                    {totalCommentsAndDocuments}
                  </Badge>
                )}
              </span>
            </Feature>
          </VisibleFor>
        )}

        {handleRenderSaveButton()}
      </div>

      {lineItem[workflowTypeKey]?.no_model_upload && (
        <Feature featureName={FEATURES.NO_MODEL_UPLOAD}>
          <div className="checkbox mb15">
            <Form.Check
              inline
              name="noModelUpload"
              checked={noModelUpload}
              onChange={handleInputChange}
              disabled={!lineItem[workflowTypeKey]?.no_model_upload || isReadOnly}
              type="checkbox"
              label={(
                <strong>
                  <FormattedMessage
                    id="record.itar"
                    defaultMessage="No Design Upload (ITAR)"
                  />
                </strong>
              )}
            />
          </div>
        </Feature>
      )}
      {showMeshQuality && (
        <FormRow id="field.meshQuality" defaultMessage="Mesh Quality">
          <Form.Text>
            <ModelMeshQuality
              model={model}
              lineItem={lineItem}
              noModelUpload={lineItem[workflowTypeKey]?.no_model_upload}
              onMeshHealRepair={modelToRepair =>
                onMeshHealRepair(modelToRepair)}
            />
          </Form.Text>
        </FormRow>
      )}
      <Feature featureName={FEATURES.LINE_ITEM_EXTENDED_DETAILS}>
        <>
          <FormRow id="field.partName" defaultMessage="Part Name">
            <FormControl
              name="partName"
              value={partName}
              type="text"
              onChange={handleInputChange}
              disabled={isReadOnly}
            />
          </FormRow>
          <FormRow
            id={options.customerID.id}
            defaultMessage={options.customerID.defaultMessage}
          >
            <FormControl
              name="customerId"
              value={customerId}
              type="text"
              onChange={handleInputChange}
              disabled={isReadOnly}
            />
          </FormRow>
        </>
      </Feature>
      {revisionFieldReference && (
        <CustomFieldComponent
          reference={revisionFieldReference}
          value={revisionFieldValue && revisionFieldValue.value}
          onChange={onCustomFieldChange}
          variant={CustomFieldComponent.variants.form}
        />
      )}
      <FormRow id="field.status" defaultMessage="Status">
        {!options.status.editable && (
          <Form.Text>
            {
              // Status might be empty for some time right after LI creation
              // (after POST, before event-stream UPDATED event)
              status && (
                <FormattedMessage
                  id={LINE_ITEM_STATUS_MAP[status].id}
                  defaultMessage={LINE_ITEM_STATUS_MAP[status].message}
                />
              )
            }
          </Form.Text>
        )}

        {options.status.editable && (
          <FormControl
            name="status"
            as="select"
            onChange={handleInputChange}
            value={status}
            required
            disabled={isReadOnly}
          >
            {statusOptions &&
              statusOptions.map(statusOption => (
                <FormattedMessageMappingOption
                  mapping={LINE_ITEM_STATUS_MAP}
                  value={statusOption}
                  key={statusOption}
                />
              ))}
          </FormControl>
        )}
      </FormRow>
      {!noModelUpload && lineItem[workflowTypeKey]?.no_model_upload && (
        <FormRow id="field.uploadModel" defaultMessage="Upload Model">
          { modelUpload ? (
            <div className="d-flex justify-content-between">
              <p>{modelUpload.name}</p>
              <Button
                size="xs"
                link="danger"
                onClick={handleFileRemove}
              >
                <Fa name="times" />
              </Button>
            </div>
          )
            : (
              <FileInput
                name="model"
                fileType={FileInput.fileTypes.model}
                required={false}
                onlyFileInput
                handleFileChange={handleFileChange}
              />
            ) }
        </FormRow>
      )}

      {/* Statuses confirmed modal */}
      <Modal
        show={showCompleteWarningModal}
        onClick={handleCloseCompleteWarningModal}
      >
        <Modal.Body>
          {modalTextPending ? (
            <span>
              <b>Warning:</b> All work steps for all items in this Line-Items
              will be marked as complete. Items already added to a Print Run
              will be left in run batches, to avoid cancelling queued/set runs.
              The prints in this line item will be marked as complete, and will
              show up in print statistics. Cancel if you do not want these
              prints to be counted in statistics.
            </span>
          ) : (
            <span>
              The prints in this line item will be marked as complete, and will
              show up in print statistics. Cancel if you do not want these
              prints to be counted in statistics.
            </span>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={submitConfirmModal}>
            Confirm
          </Button>
          <Button variant="primary" onClick={handleCloseCompleteWarningModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {visibleModal === MODALS.VIEW_WORK_PLAN && (
        <WorkPlanViewModalContainer
          onClose={onCloseWorkPlanViewModal}
          lineItemUri={lineItem.uri}
          workflowUri={lineItem.workflow}
          guidelineSuggestionsForLineItem={guidelineSuggestionsForLineItem}
          onReloadPrints={onReloadPrints}
        />
      )}

      {visibleModal === MODALS.WORKFLOW_CHANGE_CONFIRMATION && (
        <DeleteChecklistsConfirmationModal
          onClose={onCloseWorkPlanViewModal}
          onDecline={onDeleteChecklistsAndSubmit}
          lineItemUri={lineItem.uri}
          workflowUri={lineItem.workflow}
        />
      )}

      {visibleModal === MODALS.LINE_ITEM_DUPLICATE && (
        <LineItemDuplicateModal
          onClose={onCloseDuplicateModal}
          onDuplicate={onDuplicate}
          isLineItemDuplicating={isLineItemDuplicating}
          quantity={quantity}
        />
      )}

      {/* Statuses confirmed modal */}
      <Modal
        show={showRecalculationLineItemModal}
        onClick={handleCloseRecalculationLineItemModal}
      >
        <Modal.Body>
          <b>Recalculation Trigger:</b> Your prices are affected by the choices
          you make in each line item. We can recalculate prices automatically or
          give you the option every time
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={submitRecalculatePriceModal}>
            Recalculate Price
          </Button>
          <Button variant="primary" onClick={submitLeavePriceModal}>
            Leave Prices
          </Button>
        </Modal.Footer>
      </Modal>

      {!lineItem[workflowTypeKey]?.no_model_upload && (
        <div>
          {isCADToSTLConversionFeatureEnabled && model && model.conversion_original_filename && (
            <FormRow id="field.originalModelFile" defaultMessage="Original Model File">
              <div>
                <Button
                  className="p-a-0"
                  variant="link"
                  onClick={() => handleModelDownload(model.uri, 'conversion_original_content')}
                >
                  {model.conversion_original_filename}
                  <Fa name="download" className="spacer-left" />
                </Button>
              </div>
            </FormRow>
          )}
        </div>
      )}

      {options.layerThickness.show && (
        <FormRow
          id="field.layer_thickness"
          defaultMessage="Layer Thickness"
          isRequired
        >
          <FormControl
            min={MODEL_LAYER_THICKNESS_SETTINGS.MIN}
            max={MODEL_LAYER_THICKNESS_SETTINGS.MAX}
            name="layerThickness"
            onBlur={handleLayerThicknessBlur}
            onChange={handleInputChange}
            required
            step={MODEL_LAYER_THICKNESS_SETTINGS.STEP}
            type="number"
            value={layerThickness}
            disabled={isReadOnly}
          />
        </FormRow>
      )}

      <FormRow id="field.quantity" defaultMessage="Quantity" isRequired>
        <div className="d-flex align-items-center">
          <FormControl
            name="quantity"
            value={quantity}
            type="number"
            min="1"
            onChange={handleInputChange}
            required
            disabled={isReadOnly || !isDesignStage}
          />
          {!isDesignStage && (
            <Button
              className="spacer-left"
              onClick={() => setVisibleModal(MODALS.LINE_ITEM_DUPLICATE)}
              variant="primary"
              size="sm"
            >
              <FormattedMessage id="button.duplicate" defaultMessage="Duplicate" />
            </Button>
          )}
        </div>
      </FormRow>
      {options.discountByQuantity.show && (
        <FormRow
          id="field.discountByQuantity"
          defaultMessage="Discount by Quantity (%)"
        >
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="bottom"
            overlay={discountOverlay}
          >
            <FormControl
              name="discountByQuantity"
              value={discountByQuantity}
              type="number"
              min="0"
              onChange={handleInputChange}
              disabled={isReadOnly}
            />
          </OverlayTrigger>
        </FormRow>
      )}
      <VisibleFor unrestricted>
        <Feature featureName={FEATURES.AUTORUN}>
          <FormRow id="field.autorun" defaultMessage="Auto-create Run">
            <Form.Check
              name="autorun"
              type="checkbox"
              onChange={handleInputChange}
              checked={autorun}
              disabled={isReadOnly}
            />
          </FormRow>
        </Feature>
      </VisibleFor>

      <Feature featureName={FEATURES.TRAVELER}>
        {options.traveler.show && (
          <FormRow id="field.traveler" defaultMessage="Traveler">
            <a
              href={`${Config.HOST.NAUTILUS}/traveler/?line_item=${lineItem.uri}`}
              target="_blank"
              rel="noopener noreferrer"
              type="download"
            >
              <FormattedMessage
                id="button.download"
                defaultMessage="Download"
              />
            </a>
          </FormRow>
        )}
      </Feature>

      {options.baseMaterial.show && (
        <FormRow
          id="field.baseMaterial"
          defaultMessage="Base Material"
          isRequired
        >
          <div className="d-flex align-items-center">
            <InputGroup className="w100">
              <div className="d-flex w-full">
                <SelectSingle
                  name="baseMaterial"
                  data={baseMaterialOptions}
                  value={baseMaterial}
                  handleOnChange={handleInputChange}
                  required
                  imitateOnChangeEvent
                  disabled={!options.baseMaterial.editable || isReadOnly}
                />
                {
                // Render Context Tooltip Addon only if material is available in context
                  (assemblyPartMeta && assemblyPartMeta.context && assemblyPartMeta.context.material)
                && (
                  <InputGroup.Text>
                    <AssemblyPartMetaContextTooltip assemblyPartMeta={assemblyPartMeta} />
                  </InputGroup.Text>
                )
                }
                <InputGroup.Text>
                  {baseMaterial && (
                    <div
                      style={{
                        margin: '0 auto',
                        width: 20,
                        height: 20,
                        backgroundColor: baseMaterialColor,
                      }}
                    />
                  )}
                </InputGroup.Text>
                <VisibleFor unrestricted>
                  <InputGroup.Text>
                    {baseMaterialColor && <ResourceLink href={baseMaterialUrl} />}
                  </InputGroup.Text>
                </VisibleFor>
              </div>
            </InputGroup>
            <FormattedMessage
              id="record.lineItem.locationFilterMessage"
              defaultMessage="The Materials you can select from are those Materials which are available at the Order's Location. To see other Materials that may be available, please change the Order's Location. To see All Materials available in your bureau, set the Order Location to Any."
            >
              {text => (
                <Tooltip id="locationFilteringMessage" placement="left">{text}</Tooltip>
              )}
            </FormattedMessage>
          </div>
        </FormRow>
      )}

      {options.supportMaterial.show && (
        <FormRow id="field.supportMaterial" defaultMessage="Support Material">
          <InputGroup className="w100">
            <div className="d-flex align-items-center w-full">
              <SelectSingle
                name="supportMaterial"
                data={supportMaterials}
                value={supportMaterial}
                handleOnChange={handleInputChange}
                imitateOnChangeEvent
                disabled={!options.supportMaterial.editable || isReadOnly}
              />
              {supportMaterialColor && (
                <InputGroup.Text>
                  <div
                    style={{
                      margin: '0 auto',
                      width: 20,
                      height: 20,
                      backgroundColor: supportMaterialColor,
                    }}
                  />
                </InputGroup.Text>
              )}
              {(supportMaterial) && (
                <VisibleFor unrestricted>
                  <InputGroup.Text style={{ minWidth: '62px' }}>
                    <ResourceLink href={supportMaterialUrl} />
                  </InputGroup.Text>
                </VisibleFor>
              )}
            </div>
          </InputGroup>
        </FormRow>
      )}

      {isDebugModeEnabled &&
      (isAdditiveWorkflowFeatureEnabled || isPowderWorkflowFeatureEnabled) &&
       (
         <FormRow id="workflowType" defaultMessage="Workflow Type">
           <FormControl
             as="select"
             type="select"
             value={workflowTypeInitialValue}
             disabled
           >
             <option key="additiveWorkflow" value="additiveWorkflow">
               Additive
             </option>
             <option key="powderWorkflow" value="powderWorkflow">
               Powder
             </option>
           </FormControl>
         </FormRow>
       )}

      {options.workflow.show &&
        (status === LINE_ITEM_STATUS.COMPLETE ? (
          <FormRow id="workflow" defaultMessage="Production Workflow">
            <p className="form-control-static">Complete</p>
          </FormRow>
        ) : (
          <FormRow
            id="workflow"
            defaultMessage="Production Workflow"
            isRequired={options.workflow.required}
          >
            <InputGroup className="w100">
              <div className="d-flex w-full">
                <SelectSingleLazy
                  name="workflow"
                  dataTestId="lineItemWorkflow"
                  data={nonSpecimenWorkflows}
                  value={workflow}
                  handleOnChange={handleInputChange}
                  imitateOnChangeEvent
                  required
                  disabled={isReadOnly}
                  isOptionDisabledCallback={item => item.usage_state === WORKFLOW_USAGE_STATES.ARCHIVED}
                  onFetchMore={onFetchMoreWorkflows}
                />
                {workflow && (
                  <VisibleFor unrestricted>
                    <InputGroup.Text>
                      {!options.workflow.editable && workInstructionsExist && (
                        <FormattedMessage
                          id="workflowInstructionTooltip"
                          defaultMessage="Production Workflow can’t be changed once work instructions are created for this line item."
                        >
                          {text => (
                            <Tooltip id="workflowInstructionTooltip">{text}</Tooltip>
                          )}
                        </FormattedMessage>
                      )}
                      <ResourceLink
                        href={getRouteURI(ROUTES.WORKFLOW_EDIT, { uuid: extractUuid(workflow) })}
                      />
                    </InputGroup.Text>
                  </VisibleFor>
                )}
              </div>
            </InputGroup>
          </FormRow>
        ))}

      <VisibleFor unrestricted>
        <FormRow
          id="field.workInstructions"
          defaultMessage="Work Instructions"
          labelSuffix={lineItem.workflow ? null : (
            <Tooltip
              id="workflowSelectionTooltip"
              trigger={(<Fa name="question-circle" />)}
            >
              Requires workflow selection
            </Tooltip>
          )}
        >
          <Button
            size="xs"
            variant="primary"
            className="pos-r"
            onClick={() => setVisibleModal(MODALS.VIEW_WORK_PLAN)}
            disabled={!usedWorkflow}
          >
            <Fa name="eye" />{' '}
            <FormattedMessage
              id="button.viewEditWorkInstructions"
              defaultMessage="View and Edit Work Instructions"
            />
            {isGuidelineEngineFeatureEnabled && guidelineSuggestionsForLineItem?.length > 0 && (
              <Badge
                className="badge badge-sm d-flex align-items-center justify-content-center text-white pos-a"
                bg="info"
                style={{ left: '95%',
                  top: -10,
                  height: 20,
                  width: 20,
                  borderRadius: 20 }}
              >
                {guidelineSuggestionsForLineItem.length > 0 && (
                  <span>{guidelineSuggestionsForLineItem.length}</span>
                )}
              </Badge>
            )}
          </Button>
          {(anyPiecesHaveModifiedWorkflow && !isPiecesOrWorkflowfetching) && (
            <RCTooltip
              placement="right"
              destroyTooltipOnHide
              overlayInnerStyle={{ padding: '10px', wordBreak: 'break-word' }}
              mouseLeaveDelay={0.4}
              overlay={(

                <p>One or more of the pieces for this line-item <br />
                  were manufactured with a different workflow
                </p>

              )}
            >

              <FontAwesomeIcon icon={faTriangleExclamation} className="spacer-left" style={{ color: '#ffd500' }} />
            </RCTooltip>
          )}
          {(fetchingGuidelineSuggestionsForLineItem || fetchingProcessSteps)
              && (
                <Loading />
              )}
        </FormRow>
      </VisibleFor>

      <Feature featureName={FEATURES.EXTERNAL_PRODUCTION_ESTIMATE}>
        {options.infillStrategy.show && infillStrategies.length > 0 && (
          <FormRow id="field.infill_strategy" defaultMessage="Infill Strategy">
            <FormControl
              name="infill_strategy"
              value={infillStrategy}
              as="select"
              onChange={handleInputChange}
              disabled={isReadOnly}
            >
              {infillStrategies.map(infillStrategyOption => (
                <option
                  key={infillStrategyOption.uri}
                  value={infillStrategyOption.uri}
                >
                  {infillStrategyOption.name}
                </option>
              ))}
            </FormControl>
          </FormRow>
        )}
      </Feature>

      <Feature featureName={FEATURES.EXTERNAL_PRODUCTION_ESTIMATE}>
        {options.supportStrategy.show && supportStrategies.length > 0 && (
          <FormRow
            id="field.support_strategy"
            defaultMessage="Support Strategy"
          >
            <FormControl
              name="support_strategy"
              value={supportStrategy}
              as="select"
              onChange={handleInputChange}
              disabled={isReadOnly}
            >
              {supportStrategies.map(supportStrategyOption => (
                <option
                  key={supportStrategyOption.uri}
                  value={supportStrategyOption.uri}
                >
                  {supportStrategyOption.name}
                </option>
              ))}
            </FormControl>
          </FormRow>
        )}
      </Feature>

      {options.notes.show && (
        <FormRow
          id="field.notes"
          defaultMessage="Notes"
          isRequired={options.notes.required}
        >
          <FormControl
            name="notes"
            value={notes}
            as="textarea"
            onChange={handleInputChange}
            required={options.notes.required}
            placeholder={
              options.notes.editable ? options.notes.placeholder : ''
            }
            disabled={!options.notes.editable || isReadOnly}
          />
        </FormRow>
      )}

      <Feature featureNames={[FEATURES.DESIGN_COST, FEATURES.ORDER_QUOTE]} featureNamesEnforceAll>
        {options.designHours.show && (
          <FormRow id="field.design_hours" defaultMessage="Design Hours">
            <FormControl
              name="designHours"
              value={designHours}
              type="number"
              min="0"
              onChange={handleInputChange}
              disabled={isReadOnly}
            />
          </FormRow>
        )}
      </Feature>

      {options.materialUsed.show && (
        <FormRow id="materialUsed" defaultMessage="Material Used">
          <Form.Text>{materialUsed}</Form.Text>
        </FormRow>
      )}

      {options.bureauCost.show && (
        <FormRow
          id={options.bureauCost.id}
          defaultMessage={options.bureauCost.defaultMessage}
        >
          <Form.Text>{bureauCost}</Form.Text>
        </FormRow>
      )}

      <CustomFieldList
        customFieldReferences={visiblelineItemCustomFieldReferencesWithOptions}
        customFieldValues={customFieldValues}
        onChange={onCustomFieldChange}
        readOnly={isReadOnly}
      />

      {showPartSettingsField && <AssemblyPartSettingsField assemblyPartMeta={assemblyPartMeta} />}
    </Form>
  );
};

LineItemEditForm.defaultProps = {
  autorun: false,
  modelUpload: null,
  model: null,
  notes: null,
  isCurrentUserRestricted: false,
  isOrderBusinessSegmentFeatureEnabled: false,
  isBoeingOrderFieldsFeatureEnabled: false,
  is3MOrderFieldsFeatureEnabled: false,
  supportMaterial: null,
  supportMaterialColor: null,
  workflow: null,
  infillStrategy: null,
  supportStrategy: null,
  uploadModel: null,
  designHours: null,
  discountByQuantity: null,
  isModelUploading: false,
  isLineItemSubmitting: false,
  isLineItemDuplicating: false,
  partName: null,
  customerId: null,
  readOnly: false,
  showRecalculationLineItemModal: false,
  isCADToSTLConversionFeatureEnabled: false,
  onFetchMoreWorkflows: null,
  assemblyPartMeta: null,
};

LineItemEditForm.propTypes = {
  autorun: PropTypes.bool,
  noModelUpload: PropTypes.bool.isRequired,
  customFieldValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customLineItemFieldReferences: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customOrderFieldReferences: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleLayerThicknessBlur: PropTypes.func.isRequired,
  handleModelDownload: PropTypes.func.isRequired,
  submitRecalculatePriceModal: PropTypes.func.isRequired,
  submitLeavePriceModal: PropTypes.func.isRequired,
  isModelUploading: PropTypes.bool,
  isLineItemSubmitting: PropTypes.bool,
  isLineItemDuplicating: PropTypes.bool,
  isCurrentUserRestricted: PropTypes.bool,
  isOrderBusinessSegmentFeatureEnabled: PropTypes.bool,
  isBoeingOrderFieldsFeatureEnabled: PropTypes.bool,
  is3MOrderFieldsFeatureEnabled: PropTypes.bool,
  showCompleteWarningModal: PropTypes.bool.isRequired,
  showRecalculationLineItemModal: PropTypes.bool,
  modalTextPending: PropTypes.bool.isRequired,
  layerThickness: PropTypes.number.isRequired,
  lineItem: lineItemResourceType.isRequired,
  partName: PropTypes.string,
  customerId: PropTypes.string,
  baseMaterial: PropTypes.string.isRequired,
  baseMaterialColor: PropTypes.string.isRequired,
  baseMaterialUrl: PropTypes.string.isRequired,
  baseMaterials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  baseMaterialsByLocationUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  model: modelResourceType,
  modelLibraries: PropTypes.shape({}).isRequired,
  modelUpload: PropTypes.shape({ name: PropTypes.string }),
  uploadModel: PropTypes.shape({
    name: PropTypes.string,
    percent: PropTypes.number,
  }),
  notes: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onCustomFieldChange: PropTypes.func.isRequired,
  onMeshHealRepair: PropTypes.func.isRequired,
  onReloadPrints: PropTypes.func.isRequired,
  onReloadChecklistLinkings: PropTypes.func.isRequired,
  handleCloseCompleteWarningModal: PropTypes.func.isRequired,
  handleCloseRecalculationLineItemModal: PropTypes.func.isRequired,
  submitConfirmModal: PropTypes.func.isRequired,
  quantity: PropTypes.string.isRequired,
  discountByQuantity: PropTypes.number,
  designHours: PropTypes.number,
  status: PropTypes.string.isRequired,
  submitToModelLibrary: PropTypes.func.isRequired,
  supportMaterial: PropTypes.string,
  supportMaterialColor: PropTypes.string,
  supportMaterialUrl: PropTypes.string.isRequired,
  supportMaterials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  workflow: PropTypes.string,
  infillStrategy: PropTypes.string,
  supportStrategy: PropTypes.string,
  workflows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  workChecklistLinkings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  infillStrategies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  supportStrategies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  readOnly: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isDebugModeEnabled: PropTypes.bool.isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
  order: orderResourceType.isRequired,
  isCADToSTLConversionFeatureEnabled: PropTypes.bool,
  handleFileChange: PropTypes.func.isRequired,
  handleFileRemove: PropTypes.func.isRequired,
  onFetchMoreWorkflows: PropTypes.func,
  comments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  modelLibraryModal: PropTypes.shape({
    modelLibraryModalVisible: PropTypes.bool,
    setModelLibraryModalVisible: PropTypes.bool,
  }).isRequired,
  isGuidelineEngineFeatureEnabled: PropTypes.bool.isRequired,
  guidelineSuggestionsForLineItem: PropTypes.arrayOf(PropTypes.shape({
    resources: PropTypes.arrayOf(PropTypes.shape({})),
  })).isRequired,
  fetchingGuidelineSuggestionsForLineItem: PropTypes.bool.isRequired,
  fetchingProcessSteps: PropTypes.bool.isRequired,
  assemblyPartMeta: assemblyPartMetaResourceType,
  features: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  anyPiecesHaveModifiedWorkflow: PropTypes.bool.isRequired,
  isAdditiveWorkflowFeatureEnabled: PropTypes.bool.isRequired,
  isPowderWorkflowFeatureEnabled: PropTypes.bool.isRequired,
  workflowTypeKey: PropTypes.string.isRequired,
  workflowTypeEstimatesKey: PropTypes.string.isRequired,
  isPiecesOrWorkflowfetching: PropTypes.bool.isRequired,
};

export default LineItemEditForm;
