import React from 'react';
import PropTypes from 'prop-types';

import { Badge, Button, Col, Row } from 'react-bootstrap';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';
import VisibleFor from 'rapidfab/components/VisibleFor';
import DisabledFor from 'rapidfab/components/DisabledFor';
import DisabledIconWithTooltip from 'rapidfab/components/DisabledIconWithTooltip';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import { ROUTES } from 'rapidfab/constants';
import { postProcessorTypeResourceType } from 'rapidfab/types';
import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';

const NavbarLinks = ({ isServices, isGroupQualificationsFeatureEnabled }) => (
  <Row>
    <Col xs={12}>
      <VisibleFor bureauUserRole>
        <div className="pull-right">
          <DisabledFor
            nonManager
            /* Disable for Non Managers only when `group-qualifications` feature is enabled. No limitations otherwise */
            alwaysEnabled={!isGroupQualificationsFeatureEnabled}
            disabledPrefix={<DisabledIconWithTooltip placement="bottom" />}
          >
            {({ disabled }) => (
              <Button
                variant="primary"
                size="sm"
                href={
                  isServices
                    ? getRouteURI(ROUTES.POST_PROCESSOR_SERVICE_NEW)
                    : getRouteURI(ROUTES.POST_PROCESSOR_NEW)
                }
                disabled={disabled}
              >
                <Fa name="plus" className="spacer-right" />
                {isServices ? (
                  <FormattedMessage
                    id="record.postProcessorService.add"
                    defaultMessage="Add Post Processor Service"
                  />
                ) : (
                  <FormattedMessage
                    id="record.postProcessor.add"
                    defaultMessage="Add Post Processor"
                  />
                )}
              </Button>
            )}
          </DisabledFor>
        </div>
      </VisibleFor>
    </Col>
  </Row>
);
NavbarLinks.propTypes = {
  isServices: PropTypes.bool.isRequired,
  isGroupQualificationsFeatureEnabled: PropTypes.bool.isRequired,
};

const PostProcessors = ({
  data,
  fetching,
  locations,
  locationsByUri,
  postProcessorTypes,
  isServices,
  isServiceProviderRole,
  isGroupQualificationsFeatureEnabled,
  isAssemblyColumnShown,
  ...componentProps
}) => {
  // const listName = isServices ? 'postProcessorServices' : 'postProcessors';
  const columns = [
    {
      type: 'uuid',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      resource: isServices ? 'post-processor-service' : 'post-processor',
    },
    {
      type: 'custom',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      isSortable: true,
      Cell: ({ row: { original } }) => (
        <div>
          {original.name}
          {original.archived && (
            <Badge bg="default" className="badge-sm ms-2">
              <FormattedMessage id="field.archived" defaultMessage="Archived" />
            </Badge>
          )}
        </div>
      ),
    },
    {
      type: 'text',
      uid: 'field.duration',
      accessor: 'duration',
      defaultMessage: 'Duration',
      isSortable: true,
    },
    !isServices &&
    {
      type: 'resource',
      uid: 'field.location',
      accessor: 'location',
      defaultMessage: 'Location',
      resource: 'location',
      resources: locationsByUri,
      isSortable: true,
    },
    !isServices &&
    {
      type: 'resource',
      uid: 'field.postProcessorType',
      accessor: 'post_processor_type',
      defaultMessage: 'Post Processor Type',
      slug: 'post-processor-type',
      resource: 'post_processor_type',
      resources: postProcessorTypes,
      isSortable: true,
    },
    (isServices && !isServiceProviderRole) &&
    {
      type: 'service',
      uid: 'manage.serviceProviders',
      accessor: 'serviceProviders',
      defaultMessage: 'Service Providers',
      isSortable: true,
    },
    (isAssemblyColumnShown) &&
    {
      type: 'bool',
      uid: 'assembly',
      accessor: 'is_assembly',
      defaultMessage: 'Sub-Assembly',
      isSortable: true,
    },
  ];

  return (
    <TableWithSearching
      {...componentProps}
      locations={locations}
      breadcrumbs={[
        'inventory',
        isServices ? 'postProcessorServices' : 'postProcessors',
      ]}
      navbar={(
        <NavbarLinks
          isServices={isServices}
          isGroupQualificationsFeatureEnabled={
            isGroupQualificationsFeatureEnabled
          }
        />
      )}
      tableID="postProcessors"
      isFetching={fetching}
      data={data}
      columns={columns}
      withDefaultPagination
      isFilteringEnabled
      initialSortedDesc={false}
      initialSortedColumn="name"
      isManualSoringEnabled={false}
      withBreadcrumbs
    />
  );
};

PostProcessors.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  locationsByUri: PropTypes.objectOf(PropTypes.shape({})),
  postProcessorTypes: PropTypes.objectOf(postProcessorTypeResourceType).isRequired,
  isServices: PropTypes.bool,
  isAssemblyColumnShown: PropTypes.bool,
  isServiceProviderRole: PropTypes.bool,
  isGroupQualificationsFeatureEnabled: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetching: PropTypes.bool.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      archived: PropTypes.string,
    }),
  }).isRequired,
};

PostProcessors.defaultProps = {
  isAssemblyColumnShown: false,
  isServices: false,
  // This prop is Required when isServices=true
  isServiceProviderRole: false,
  // This prop is Required when isServices=false
  locationsByUri: {},
};

export default PostProcessors;
