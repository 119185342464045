// This is a temporary file
// which contains constants that will removed once functionality is not needed

export const ANATOMICAL_MODEL_STATUSES = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  COMPLETE: 'complete',
  ERROR: 'error',
};

export const PART_STATUSES = {
  NOT_UPLOADED: 'not-uploaded',
  PROCESSING: 'processing',
  PROCESSED: 'processed',
  ERROR: 'error',
};

// Keys are copied from `MANUFACTURING_PROCESSES` constant
// to be able to map values in the UI for the 3rd part config
export const MANUFACTURING_PROCESS_TO_PRINTER_CONFIGURATION_MAP = {
  SLA: 'SLA',
  SLS: 'SLS',
  FDM: 'FDM',
  Polyjet: 'Polyjet',
  'Polyjet Anatomy': 'Polyjet - Anatomy',
};

// This Json is provided via 3rd party
// Leaving all metadata to be able to replace values when needed
// Copy-paste json content into this constant, when there is a new version
export const PRINTER_CONFIGURATION_JSON = {
  // **NOTE**: If this value is changed, you need to update the Anatomical ZIP file utility in Nautilus
  // Specifically the `printerConfigVersion` key in the `package.json` file of the generated zip file
  // Can be found in `store_package_json()` in `nautilus/backend/anatomical_model/generate_build_file.py
  version: 5,
  date: '2024-08-23',
  language: 'en',
  printerTechnology: [
    {
      name: 'Polyjet',
      description: 'High resolution, multi-material printer',
      rgb: true,
      transparency: true,
      defaultThickness: '2.0',
      flexibility: [
        '30',
        '40',
        '50',
        '60',
        '70',
        '85',
        '95',
        '100',
      ],
      color: [],
      finish: [
        'Standard',
        'Sanding',
        'Polishing',
      ],
      hollowShells: false,
      hollowDefault: false,
      anatomy: null,
    },
    {
      name: 'Polyjet - Anatomy',
      description: 'Anatomical simulation printer',
      rgb: false,
      transparency: false,
      defaultThickness: '2.0',
      flexibility: ['100'],
      color: [],
      finish: ['Standard'],
      hollowShells: false,
      hollowDefault: false,
      anatomy: {
        name: 'J-Series Digital Anatomy Printer',
        description: 'DAP Software Settings',
        family: [{
          name: 'Blood Vessel',
          element: [{
            name: 'Clot',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Fibrotic',
                default: true,
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              },
              {
                name: 'Highly Fribrotic',
                color: [
                  'A62162FF',
                  'F0F0F0FF',
                ],
              },
              {
                name: 'Soft',
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Fixtures',
            visible: false,
            property: [{
              name: 'Rigidity',
              value: [{
                name: 'Rigidity 1; Shore A:40',
                default: true,
                color: [
                  '84638DFF',
                  'FBFE80FF',
                ],
              },
              {
                name: 'Rigidity 2; Shore A:50',
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              {
                name: 'Rigidity 3; Shore A:60',
                color: [
                  '80467FFF',
                  'F9FE94FF',
                ],
              },
              {
                name: 'Rigidity 4; Shore A:70',
                color: [
                  '82427DFF',
                  'F9FE94FF',
                ],
              },
              {
                name: 'Rigidity 5; Shore A:85',
                color: [
                  '863476FF',
                  'FCFFA8FF',
                ],
              },
              {
                name: 'Rigidity 6; High Dimensional Stability',
                color: [
                  'A62162FF',
                  'F0F0F0FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Frame',
            visible: false,
            property: [{
              name: 'Rigidity',
              value: [{
                name: 'Rigidity 1; Shore A:30',
                default: true,
                color: [
                  'FFFF64FF',
                ],
              },
              {
                name: 'Rigidity 2; Shore A:40',
                default: true,
                color: [
                  '84638DFF',
                  'FBFE80FF',
                ],
              },
              {
                name: 'Rigidity 3; Shore A:50',
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              {
                name: 'Rigidity 4; Shore A:60',
                color: [
                  '80467FFF',
                  'F9FE94FF',
                ],
              },
              {
                name: 'Rigidity 5; Shore A:70',
                color: [
                  '82427DFF',
                  'F9FE94FF',
                ],
              },
              {
                name: 'Rigidity 6; Shore A:85',
                color: [
                  '863476FF',
                  'FCFFA8FF',
                ],
              },
              {
                name: 'Rigidity 7; High Dimensional Stability',
                color: [
                  'A62162FF',
                  'F0F0F0FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Gel Support',
            visible: false,
            property: [{
              name: '',
              value: [{
                name: '',
                default: true,
                color: [
                  'FFFF64FF',
                ],
              }],
            }],
          },
          {
            name: 'Inlets',
            visible: true,
            property: [{
              name: 'Rigidity',
              value: [{
                name: 'Rigidity 1; Shore A:70',
                default: true,
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              },
              {
                name: 'Rigidity 2; Shore A:85',
                color: [
                  '863476FF',
                  'FCFFA8FF',
                ],
              },
              {
                name: 'Rigidity 3; Shore A:95',
                color: [
                  '8B2B72FF',
                  'FDFFB2FF',
                ],
              },
              {
                name: 'Rigidity 4; High Dimensional Stability',
                color: [
                  'A62162FF',
                  'F0F0F0FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Pure Gel Support',
            visible: false,
            property: [{
              name: '',
              value: [{
                name: '',
                default: true,
                color: [
                  'FFFF64FF',
                ],
              }],
            }],
          },
          {
            name: 'Reinforcements',
            visible: false,
            property: [{
              name: 'Rigidity',
              value: [{
                name: 'Rigidity 1; Shore A:70',
                default: true,
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              },
              {
                name: 'Rigidity 2; Shore A:95',
                color: [
                  '8B2B72FF',
                  'FDFFB2FF',
                ],
              },
              {
                name: 'Rigidity 3; High Dimensional Stability',
                color: [
                  'A62162FF',
                  'F0F0F0FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Solid Tumor',
            visible: true,
            property: [{
              name: 'Type',
              value: [{
                name: 'A: Soft non-encapsulated tumor',
                default: true,
                color: [
                  'EE4F1AFF',
                ],
              },
              {
                name: 'B: Soft encapsulated tumor',
                color: [
                  'C03A0EFF',
                ],
              },
              {
                name: 'C: Stiff non-encapsulated tumor',
                color: [
                  'F0F0F0FF',
                ],
              },
              {
                name: 'D: Soft uniform tumor',
                color: [
                  'FFFF64FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Suturable Vessel Wall',
            visible: true,
            property: [{
              name: 'Suture Strength',
              value: [{
                name: 'Medium Strength Vessel',
                default: true,
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              },
              {
                name: 'High Strength Vessel',
                color: [
                  'A62162FF',
                  'F0F0F0FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Valve Annulus',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Soft',
                default: true,
                color: [
                  'E6D2CAFF',
                ],
              },
              {
                name: 'Moderately Stiff',
                color: [
                  'EDE0DAFF',
                ],
              },
              {
                name: 'Stiffened',
                color: [
                  'DADADAFF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Valve Leaflet',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Moderately Stiff',
                default: true,
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              {
                name: 'Stiffened',
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              },
              {
                name: 'Stiff',
                color: [
                  'A62162FF',
                  'F0F0F0FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Vascular Calcification',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Soft Calcium Deposits',
                default: true,
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              {
                name: 'Moderately Stiff Calcium Deposits',
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              },
              {
                name: 'Stiff Calcium Deposits',
                color: [
                  'A62162FF',
                  'F0F0F0FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Vessel Wall',
            visible: true,
            property: [{
              name: 'Compliance',
              value: [{
                name: 'Compliant',
                default: true,
                color: [
                  'FFFF64FF',
                ],
              },
              {
                name: 'Moderately Compliant',
                color: [
                  '84638DFF',
                  'FBFE80FF',
                ],
              },
              {
                name: 'Slightly Compliant',
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              {
                name: 'Low Compliant',
                color: [
                  '80467FFF',
                  'F9FE94FF',
                ],
              },
              {
                name: 'Semi-Rigid',
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              },
              {
                name: 'Rigid',
                color: [
                  '863476FF',
                  'FCFFA8FF',
                ],
              },
              ],
            }],
          },
          ],
        },
        {
          name: 'General Anatomy',
          element: [{
            name: 'Dense Connective Tissues',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Soft',
                default: true,
                color: [
                  '80467FFF',
                  'F9FE94FF',
                ],
              },
              {
                name: 'Rigid',
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              },
              {
                name: 'Highly Rigid',
                color: [
                  '863476FF',
                  'FCFFA8FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Gel Support',
            visible: false,
            property: [{
              name: '',
              value: [{
                name: '',
                default: true,
                color: [
                  'FFFF64FF',
                ],
              }],
            }],
          },
          {
            name: 'Hollow Internal Organs',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Compliant',
                default: true,
                color: [
                  'FFFF64FF',
                ],
              },
              {
                name: 'Moderately Compliant',
                color: [
                  '84638DFF',
                  'FBFE80FF',
                ],
              },
              {
                name: 'Slightly Compliant',
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              {
                name: 'Low Compliant',
                color: [
                  '80467FFF',
                  'F9FE94FF',
                ],
              },
              {
                name: 'Semi-Rigid',
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              },
              {
                name: 'Rigid',
                color: [
                  '863476FF',
                  'FCFFA8FF',
                ],
              },
              {
                name: 'Highly-Rigid',
                color: [
                  'A62162FF',
                  'F0F0F0FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Liver',
            visible: true,
            property: [{
              name: 'Type',
              value: [{
                name: 'Highly Contractile',
                default: true,
                color: [
                  'F0E6B4FF',
                ],
              },
              {
                name: 'Thicker Coated Highly Contractile',
                color: [
                  'F0E6A0FF',
                ],
              },
              {
                name: 'Moderately Stiff',
                color: [
                  'E6DC5AFF',
                ],
              },
              {
                name: 'Thicker Coated Moderately Stiff',
                color: [
                  'E6DC46FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Solid Internal Organs',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Fiber Contraction 1',
                default: true,
                color: [
                  'FC8D8DFF',
                ],
              },
              {
                name: 'Fiber Contraction 2',
                color: [
                  'FA5F5FFF',
                ],
              },
              {
                name: 'Fiber Contraction 3',
                color: [
                  'F81D1DFF',
                ],
              },
              {
                name: 'Fiber Contraction 4',
                color: [
                  'D20606FF',
                ],
              },
              {
                name: 'Fiber Contraction 5',
                color: [
                  'A30505FF',
                ],
              },
              {
                name: 'Fiber Contraction 6',
                color: [
                  '646464FF',
                ],
              },
              {
                name: 'Fiber Contraction 7',
                color: [
                  '646464FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Solid Tumor',
            visible: true,
            property: [{
              name: 'Type',
              value: [{
                name: 'A: Soft non-encapsulated tumor',
                default: true,
                color: [
                  'EE4F1AFF',
                ],
              },
              {
                name: 'B: Soft encapsulated tumor',
                color: [
                  'C03A0EFF',
                ],
              },
              {
                name: 'C: Stiff non-encapsulated tumor',
                color: [
                  'F0F0F0FF',
                ],
              },
              {
                name: 'D: Soft uniform tumor',
                color: [
                  'FFFF64FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Subcutaneous Fat',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Non-encapsulated highly contractile',
                default: true,
                color: [
                  'FFFFA5FF',
                ],
              },
              {
                name: 'Soft coated highly contractile',
                color: [
                  'FFFFD8FF',
                ],
              },
              {
                name: 'Stiff coated highly contractile',
                color: [
                  'FFFAA5FF',
                ],
              },
              {
                name: 'Non-encapsulated contractile',
                color: [
                  'FFFF91FF',
                ],
              },
              {
                name: 'Soft coated contractile',
                color: [
                  'FFFFCCFF',
                ],
              },
              {
                name: 'Stiff coated contractile',
                color: [
                  'FFFA91FF',
                ],
              },
              ],
            }],
          },
          ],
        },
        {
          name: 'Musculoskeletal',
          element: [{
            name: 'Facet Joints',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Soft',
                default: true,
                color: [
                  'FC8D8DFF',
                ],
              },
              {
                name: 'Moderately Stiff',
                color: [
                  'FA5F5FFF',
                ],
              },
              {
                name: 'Stiff',
                color: [
                  'A30505FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'General Bone',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Porous',
                default: true,
                color: [
                  'E4DDCBFF',
                ],
              },
              {
                name: 'Slightly Dense',
                color: [
                  'EDE9DEFF',
                ],
              },
              {
                name: 'Dense',
                color: [
                  'F0ECE3FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Intervertebral Discs',
            visible: true,
            property: [{
              name: 'Type',
              value: [{
                name: 'Normal',
                default: true,
                color: [
                  'FDF5B7FF',
                ],
              },
              {
                name: 'Degenerated',
                color: [
                  'C39438FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Ligament',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Soft',
                default: true,
                color: [
                  'FFFF64FF',
                ],
              },
              {
                name: 'Moderately Stiff',
                color: [
                  '84638DFF',
                  'FBFE80FF',
                ],
              },
              {
                name: 'Stiff',
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Long Bone',
            visible: true,
            property: [{
              name: 'Type',
              value: [{
                name: 'Miniature - Phalanges, Metatarsal, etc.',
                default: true,
                color: [
                  'F9F9EEFF',
                ],
              },
              {
                name: 'Medium - Ulna, Radius, etc.',
                default: true,
                color: [
                  'F9F9EEFF',
                ],
              },
              {
                name: 'Typical - Femur, Tibia, etc.',
                color: [
                  'EAE7D7FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Nerves',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Soft',
                default: true,
                color: [
                  'FC8D8DFF',
                ],
              },
              {
                name: 'Moderately Stiff',
                color: [
                  'FA5F5FFF',
                ],
              },
              {
                name: 'Stiff',
                color: [
                  'A30505FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Open End',
            visible: true,
            property: [{
              name: 'Anatomy Preset Materials',
              value: [{
                name: 'Low Transparent Open End',
                default: true,
                color: [
                  'ffff64ff',
                ],
              },
              {
                name: 'Medium Transparent Open End',
                color: [
                  'bbe0e3FF',
                ],
              },
              {
                name: 'Stiff',
                color: [
                  'FFFFFFFF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Ribs',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Moderately Stiff',
                default: true,
                color: [
                  'FEFBE7FF',
                ],
              },
              {
                name: 'Very Stiff',
                color: [
                  'FFF5D7FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Skull',
            visible: true,
            property: [{
              name: 'Density',
              value: [{
                name: 'Dense',
                default: true,
                color: [
                  'EBE8DCFF',
                ],
              }],
            }],
          },
          {
            name: 'Vertabra',
            visible: true,
            property: [{
              name: 'Porosity',
              value: [{
                name: 'Porous',
                default: true,
                color: [
                  'E0DCC9FF',
                ],
              },
              {
                name: 'Dense',
                color: [
                  'EBE8DCFF',
                ],
              },
              ],
            }],
          },
          ],
        },
        {
          name: 'Structural Heart',
          element: [{
            name: 'Clot',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Soft',
                default: true,
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              {
                name: 'Fibrotic',
                default: true,
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              },
              {
                name: 'Highly Fribrotic',
                default: true,
                color: [
                  'A62162FF',
                  'F0F0F0FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Frame',
            visible: false,
            property: [{
              name: 'Rigidity Level',
              value: [{
                name: 'Rigidity 1; ShoreA:30',
                default: true,
                color: [
                  'FFFF64FF',
                ],
              },
              {
                name: 'Rigidity 2; ShoreA:40',
                color: [
                  '84638DFF',
                  'FBFE80FF',
                ],
              },
              {
                name: 'Rigidity 3; ShoreA:50',
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              {
                name: 'Rigidity 4; ShoreA:70',
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              },
              {
                name: 'Rigidity 5; ShoreA:85',
                color: [
                  '863476FF',
                  'FCFFA8FF',
                ],
              },
              {
                name: 'Rigidity 6; ShoreA:95',
                color: [
                  '8B2B72FF',
                  'FDFFB2FF',
                ],
              },
              {
                name: 'Rigidity 7; High Dimensional Stability',
                color: [
                  'A62162FF',
                  'F0F0F0FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Gel Support',
            visible: false,
            property: [{
              name: '',
              value: [{
                name: '',
                default: true,
                color: [
                  'FFFF64FF',
                ],
              }],
            }],
          },
          {
            name: 'Myocardium',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Highly Contractile',
                default: true,
                color: [
                  'FC8D8DFF',
                ],
              },
              {
                name: 'Moderately Stiff',
                color: [
                  'FA5F5FFF',
                ],
              },
              {
                name: 'Stiffened',
                color: [
                  'F81D1DFF',
                ],
              },
              {
                name: 'Very Stiff',
                color: [
                  'D20606FF',
                ],
              },
              {
                name: 'Extremely Stiff',
                color: [
                  'A30505FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Reinforcement',
            visible: false,
            property: [{
              name: 'Rigidity Level',
              value: [{
                name: 'Rigidity 1; Shore A:50',
                default: true,
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              {
                name: 'Rigidity 2; Shore A:70',
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              },
              {
                name: 'Rigidity 3; High Dimensional Stability',
                color: [
                  'A62162FF',
                  'F0F0F0FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Solid Tumor',
            visible: true,
            property: [{
              name: 'Type',
              value: [{
                name: 'A: Soft non-encapsulated tumor',
                default: true,
                color: [
                  'EE4F1AFF',
                ],
              },
              {
                name: 'B: Soft encapsulated tumor',
                color: [
                  'C03A0EFF',
                ],
              },
              {
                name: 'C: Stiff non-encapsulated tumor',
                color: [
                  'F0F0F0FF',
                ],
              },
              {
                name: 'D: Soft uniform tumor',
                color: [
                  'FFFF64FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Valve Annulus',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Soft Healthy',
                default: true,
                color: [
                  'E6D2CAFF',
                ],
              },
              {
                name: 'Moderately Stiff',
                color: [
                  'EDE0DAFF',
                ],
              },
              {
                name: 'Stiffened Diseased',
                color: [
                  'DADADAFF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Valve Cordae',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Highly Extensible',
                default: true,
                color: [
                  'FC8D8DFF',
                ],
              },
              {
                name: 'Extensible',
                color: [
                  'A30505FF',
                ],
              },
              {
                name: 'Stiffened',
                color: [
                  'FFFF64FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Valve Leaflet',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Soft Healthy',
                default: true,
                color: [
                  'FFFF64FF',
                ],
              },
              {
                name: 'Moderately Stiff',
                default: true,
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              {
                name: 'Stiffened',
                default: true,
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              },
              {
                name: 'Extensively Stiff',
                default: true,
                color: [
                  '8B2B72FF',
                  'FDFFB2FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Valvular Calcification',
            visible: true,
            property: [{
              name: 'Stiffness',
              value: [{
                name: 'Soft',
                default: true,
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              {
                name: 'Moderately Stiff',
                color: [
                  '82427DFF',
                  'FAFF9EFF',
                ],
              },
              {
                name: 'Stiff',
                color: [
                  'A62162FF',
                  'F0F0F0FF',
                ],
              },
              ],
            }],
          },
          {
            name: 'Vessel Wall',
            visible: true,
            property: [{
              name: 'Compliance',
              value: [{
                name: 'Compliant',
                default: true,
                color: [
                  'FFFF64FF',
                ],
              },
              {
                name: 'Slightly Compliant',
                color: [
                  '815184FF',
                  'F9FE8AFF',
                ],
              },
              {
                name: 'Low Compliant',
                color: [
                  '80467FFF',
                  'F9FE94FF',
                ],
              },
              ],
            }],
          },
          ],
        },
        ],
      },
    },
    {
      name: 'FDM',
      description: 'Low resolution, Filament printer',
      rgb: false,
      transparency: false,
      defaultThickness: '2.0',
      flexibility: ['100'],
      color: [
        'FFFFFFFF', 'ffffffff',
      ],
      finish: ['Standard'],
      hollowShells: true,
      hollowDefault: false,
      anatomy: null,
    },
    {
      name: 'DLP',
      description: 'Medium resolution, high speed resin printer',
      rgb: false,
      transparency: false,
      defaultThickness: '2.0',
      flexibility: ['100'],
      color: [
        'EEE1C6FF', 'eee1c6ff',
      ],
      finish: ['Standard'],
      hollowShells: true,
      hollowDefault: false,
      anatomy: null,
    },
    {
      name: 'SLA',
      description: 'Medium resolution, standard speed resin printer',
      rgb: false,
      transparency: false,
      defaultThickness: '2.0',
      flexibility: ['100'],
      color: [
        'FFFFFFFF', 'ffffffff',
      ],
      finish: ['Standard'],
      hollowShells: true,
      hollowDefault: false,
      anatomy: null,
    },
    {
      name: 'SLS',
      description: 'Medium resolution, standard speed powder printer',
      rgb: false,
      transparency: false,
      defaultThickness: '2.0',
      flexibility: ['100'],
      color: [
        '555555FF', '555555ff',
      ],
      finish: ['Standard'],
      hollowShells: true,
      hollowDefault: false,
      anatomy: null,
    },
  ],
};

export const ELEMENT_WITHOUT_PROPERTIES_NAME = 'Gel Support';
