import React from 'react';
import { reduxFormFieldType } from 'rapidfab/types';
import PropTypes from 'prop-types';
import hexRgb from 'hex-rgb';
import rgbHex from 'rgb-hex';

const SwatchColorPicker = ({
  colors,
  colorField,
  opacityField,
}) => {
  if (colors.length === 0) return <span>No available colors</span>;
  return (
    <div className="swatch-color-picker">
      {colors.map(color => {
        const { red, green, blue, alpha } = hexRgb(color);
        const isSelected = !!colorField.value
        && hexRgb(colorField.value, { format: 'css', alpha: opacityField.value }) === hexRgb(color, { format: 'css' });
        const isTransparent = alpha === 0;
        return (
          <div
            key={color}
            className={
              `swatch-color-picker-item ${isSelected ? 'swatch-color-picker-item--selected' : ''} ${isTransparent ? 'swatch-color-picker-item--checkerboard' : ''}`
            }
            onClick={() => {
              colorField.onChange(`#${rgbHex(red, green, blue)}`);
              opacityField.onChange(alpha);
            }}
            role="button"
            tabIndex={0}
            style={{ backgroundColor: hexRgb(color, { format: 'css' }) }}
            title={`#${rgbHex(red, green, blue, alpha)}`.toUpperCase()}
          />
        );
      },
      )}
    </div>
  );
};

SwatchColorPicker.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  colorField: reduxFormFieldType.isRequired,
  opacityField: reduxFormFieldType.isRequired,
};

export default SwatchColorPicker;
