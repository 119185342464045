import React from 'react';
import { ROUTES } from 'rapidfab/constants';
import getRouteURI from 'rapidfab/utils/getRouteURI';

class LegacyEndpointRedirect extends React.Component {
  componentDidMount() {
    let locationHash = window.location.hash;

    // Some years ago ROUTES.TEMPLATE_NEW(/records/template/) route was used for email notifications,
    // so replacing /template/ with /workflow/ so that a 404 page is not shown to the users.
    if (locationHash.startsWith(`${getRouteURI(ROUTES.TEMPLATE_NEW)}/`)) {
      locationHash = locationHash.replace(ROUTES.TEMPLATE_NEW, ROUTES.WORKFLOW_CREATE);
    }

    if (locationHash.startsWith(getRouteURI(ROUTES.ANATOMICAL_MODELS_DEPRECATED))) {
      locationHash = locationHash.replace(ROUTES.ANATOMICAL_MODELS_DEPRECATED, ROUTES.ANATOMICAL_MODELS);
    }

    if (locationHash.startsWith(getRouteURI(ROUTES.ANATOMICAL_MODEL_NEW_DEPRECATED))) {
      locationHash = locationHash.replace(ROUTES.ANATOMICAL_MODEL_NEW_DEPRECATED, ROUTES.ANATOMICAL_MODEL_NEW);
    }

    if (locationHash.startsWith(getRouteURI(ROUTES.ANATOMICAL_MODEL_EDIT_DEPRECATED))) {
      locationHash = locationHash.replace(ROUTES.ANATOMICAL_MODEL_EDIT_DEPRECATED, ROUTES.ANATOMICAL_MODEL_EDIT);
    }

    // Replace doesn't push to history, otherwise the user wouldn't be able to
    // navigate back
    window.location.replace(locationHash);
  }

  render() {
    return null;
  }
}

export default LegacyEndpointRedirect;
