import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import * as Selectors from 'rapidfab/selectors';
import {
  API_RESOURCES,
  MIME_TYPES,
  MANUFACTURING_PROCESS_TO_PRINTER_CONFIGURATION_MAP,
  PRINTER_CONFIGURATION_JSON,
} from 'rapidfab/constants';
import extractUuid from 'rapidfab/utils/extractUuid';
import Actions from 'rapidfab/actions';
import Alert from 'rapidfab/utils/alert';
import { ANATOMICAL_MODEL_PART_MODAL_CONTAINER } from 'rapidfab/constants/forms';
import { AnatomicalModelPartModal } from 'rapidfab/components/AnatomicalModel';
import _find from 'lodash/find';
import { getPrinterTechnology } from '../../utils/printerConfiguration';

const AnatomicalModelPartModalContainer = props => {
  const { uuid, assemblyUri } = props;

  const bureau = useSelector(Selectors.getBureau);
  const materials = useSelector(Selectors.getMaterials);

  const isLoading = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.ANATOMICAL_MODEL_PART].get.fetching);
  const isSubmitting = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.ANATOMICAL_MODEL_PART].post.fetching ||
    state.ui.nautilus[API_RESOURCES.ANATOMICAL_MODEL_PART].put.fetching);

  const assembly = useSelector(
    state => Selectors.getUUIDResource(state, assemblyUri && extractUuid(assemblyUri)),
  );

  const printerTechName = assembly && MANUFACTURING_PROCESS_TO_PRINTER_CONFIGURATION_MAP[assembly.printing_tech];
  const printerConfig = printerTechName
        && _find(PRINTER_CONFIGURATION_JSON.printerTechnology, { name: printerTechName });

  const printerTech = getPrinterTechnology(assembly.printing_tech);

  let initialColor;
  if (printerTech) {
    if (printerTech.rgb) {
      initialColor = '#000000';
    } else if (printerTech.anatomy === null) {
      // eslint-disable-next-line prefer-destructuring
      initialColor = printerTech.color[0];
    }
  }
  const part = useSelector(state => Selectors.getUUIDResource(state, uuid));
  // Default opacity should be 1 (opaque)
  const initialValues = part || { color_opacity: 1, to_be_printed: true, color: initialColor };
  const initialFormValues = {};
  Object
    .keys(initialValues)
    .filter(key => ANATOMICAL_MODEL_PART_MODAL_CONTAINER.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  const printerAnatomyPropertyItem = _get(initialValues, 'printer_anatomy_properties[0]');
  if (printerAnatomyPropertyItem) {
    // See printer configuration file, Gel Support
    if (printerAnatomyPropertyItem.name === '') {
      initialFormValues.printer_anatomy_property_name = ' ';
    } else {
      initialFormValues.printer_anatomy_property_name = printerAnatomyPropertyItem.name;
    }

    // See printer configuration file, Gel Support
    if (printerAnatomyPropertyItem.value === '') {
      initialFormValues.printer_anatomy_property_value = ' ';
    } else {
      initialFormValues.printer_anatomy_property_value = printerAnatomyPropertyItem.value;
    }
  }

  // In hierarchical order
  const anatomyFields = [
    'printer_anatomy_family',
    'printer_anatomy_element',
    'printer_anatomy_property_name',
    'printer_anatomy_property_value',
  ];
      // If e.g a family is not set but an element is, clear the element (and all other
      // child fields)
  let parentNotSet = false;
  anatomyFields.forEach(field => {
    if (initialFormValues[field] === 'none' || parentNotSet) {
      initialFormValues[field] = undefined;
      parentNotSet = true;
    }
  });

  // Clear flexibility field if it's not valid
  const flexibilities = printerTech?.flexibility || [];
  if (!initialFormValues.flexibility || !flexibilities.includes(`${initialFormValues.flexibility}`)) {
    [initialFormValues.flexibility] = flexibilities;
  }
  const selected = {
    printerConfig,
    printerTechName: assembly.printing_tech,
    part,
    isLoading,
    isSubmitting,
    initialFormValues,
    materials,
  };

  const [file, setFile] = useState(null);

  const onFileChange = event => {
    const newFile = event.target.files[0];
    setFile(newFile);
  };

  const dispatch = useDispatch();

  // eslint-disable-next-line no-shadow
  const onInitialize = (uuid, bureauUri) => {
    if (uuid) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.ANATOMICAL_MODEL_PART].get(uuid));
    }
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau: bureauUri }));
  };

  // eslint-disable-next-line no-shadow
  const onSave = async (payload, file) => {
    const updatedPayload = { ...payload };

    if (updatedPayload.solid_infill) {
      updatedPayload.hollow_shells = null;
    }

    if (updatedPayload.flexibility) {
      updatedPayload.flexibility = Number.parseInt(updatedPayload.flexibility, 10);
    }

    Object
      .keys(updatedPayload)
      .filter(key => ANATOMICAL_MODEL_PART_MODAL_CONTAINER.FLOAT_FIELDS.includes(key))
      .forEach(key => {
        if (updatedPayload[key]) {
          updatedPayload[key] = Number.parseFloat(updatedPayload[key]);
        }
      });

    // Annoyingly, the backend requires some fields and accepts "none"
    // as an alias for empty. In order for the HTML form to correctly
    // mark fields as required, on initialisation we reset fields == "none" as
    // undefined, and then on submit we set them back to "none"
    if (!updatedPayload.printer_anatomy_family) {
      updatedPayload.printer_anatomy_family = 'none';
    }
    if (!updatedPayload.printer_anatomy_element) {
      updatedPayload.printer_anatomy_element = 'none';
    }
    if (!updatedPayload.printer_anatomy_property_name) {
      updatedPayload.printer_anatomy_property_name = 'none';
    } else if (updatedPayload.printer_anatomy_property_name === ' ') {
      // See printer configuration file, Gel Support
      updatedPayload.printer_anatomy_property_name = '';
    }

    if (!updatedPayload.printer_anatomy_property_value) {
      updatedPayload.printer_anatomy_property_value = 'none';
    } else if (updatedPayload.printer_anatomy_property_value === ' ') {
      // See printer configuration file, Gel Support
      updatedPayload.printer_anatomy_property_value = '';
    }

    updatedPayload.printer_anatomy_properties = [{
      name: updatedPayload.printer_anatomy_property_name,
      value: updatedPayload.printer_anatomy_property_value,
    }];
    delete updatedPayload.printer_anatomy_property_name;
    delete updatedPayload.printer_anatomy_property_value;

    let partRequest;
    let partUri;
    if (updatedPayload.uuid) {
      partUri = updatedPayload.uri;
      partRequest = dispatch(Actions.Api.nautilus[API_RESOURCES.ANATOMICAL_MODEL_PART]
        .put(updatedPayload.uuid, updatedPayload))
        .then(response => {
          Alert.success('Part updated successfully');
          return response;
        });
    } else {
      if (!file) {
        Alert.warning('Please upload a file first.');
        return;
      }
      updatedPayload.anatomical_model_assembly = props.assemblyUri;
      partRequest = dispatch(Actions.Api.nautilus[API_RESOURCES.ANATOMICAL_MODEL_PART].post(updatedPayload))
        .then(prepTaskResponse => {
          partUri = prepTaskResponse.headers.location;
          Alert.success('Part created successfully');
          return prepTaskResponse;
        });
    }

    partRequest.then(async response => {
      const { uploadLocation } = response.headers;
      if (file && uploadLocation) {
        await dispatch(Actions.UploadModel.upload(uploadLocation, file, MIME_TYPES.STL));
      }

      props.onClose(partUri);
    });
  };

  useEffect(() => onInitialize(uuid, bureau.uri), []);

  return (
    <AnatomicalModelPartModal
      {...props}
      {...selected}
      file={file}
      onFileChange={onFileChange}
      onSave={payload => onSave(payload, file)}
    />
  );
};

AnatomicalModelPartModalContainer.propTypes = {
  uuid: PropTypes.string,
  assemblyUri: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
AnatomicalModelPartModalContainer.defaultProps = {
  uuid: null,
};
export default AnatomicalModelPartModalContainer;
