import Constants, { MIME_TYPES } from 'rapidfab/constants';
import { postForm } from 'rapidfab/api/makeApi';

export function uploadProgress(percent) {
  return {
    type: Constants.UPLOAD_MODEL_PROGRESS,
    percent,
  };
}

function uploadSuccess(percent) {
  return {
    type: Constants.UPLOAD_MODEL_SUCCESS,
    percent,
  };
}

function uploadModel(uploadUrl) {
  return {
    type: Constants.UPLOAD_MODEL_REQUEST,
    uploadUrl,
  };
}

export function storePayload(payload) {
  return {
    type: Constants.UPLOAD_MODEL_STORE_PAYLOAD,
    payload,
  };
}

export function clearState() {
  return {
    type: Constants.UPLOAD_MODEL_CLEAR,
  };
}

export function upload(uploadUrl, model, contentType = MIME_TYPES.OCTET_STREAM) {
  return dispatch => {
    dispatch(uploadModel(uploadUrl));
    // model is instance of File. And File.name is almost always set
    // except some rare cases (e.g. in Opera Mobile)
    const contentDisposition = model.name
      // Adding file name as a part of upload process
      ? `form-data; filename="${model.name}"`
      : null;
    return postForm(
      uploadUrl,
      {},
      model,
      'PUT',
      false,
      contentType,
      percent => dispatch(uploadProgress(percent)),
      () => dispatch(uploadSuccess()),
      contentDisposition,
    );
  };
}

export function addError(errors) {
  return {
    type: Constants.UPLOAD_MODEL_ADD_ERROR,
    errors,
  };
}
