import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Container, Nav, NavItem, NavLink } from 'react-bootstrap';

import getShortUUID from 'rapidfab/utils/getShortUUID';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';

import DowntimeContainer from 'rapidfab/containers/downtime/DowntimeContainer';
import { postProcessorTypeResourceType } from 'rapidfab/types';
import PostProcessorFormContainer from 'rapidfab/containers/records/PostProcessorFormContainer';
import ChipNote from './ChipNote';

const PostProcessor = ({ handleSelectTab, postProcessor, route, tab }) => {
  const { name, uri, uuid, is_assembly: isAssembly } = postProcessor || {};
  // variable for displaying ChipNote bubble for Non-conformance processors
  const [showChipNote, setShowChipNote] = useState(false);

  return (
    <Container fluid>
      <BreadcrumbNav
        breadcrumbs={['inventory', 'postProcessors', uuid ? `${name} (${getShortUUID(uuid)})` : 'New']}
      />

      <div className="wrap-text page-header">
        <h1 className="mb15">
          {name || 'New Post Processor'}
          {isAssembly && (
            <div className="label label-info">
              Sub-Assembly
            </div>
          )}
        </h1>
        {(showChipNote || postProcessor?.post_processor_special_type === 'non_conformance_review') && <ChipNote />}
      </div>

      {uuid && (
        <Nav variant="tabs" activeKey={tab} onSelect={handleSelectTab}>
          <NavItem>
            <NavLink eventKey="1">Summary</NavLink>
          </NavItem>
          <NavItem>
            <NavLink disabled={!uri} eventKey="2">
              Downtime
            </NavLink>
          </NavItem>
        </Nav>
      )}
      {tab === '1' && (
        <PostProcessorFormContainer
          displayChipNote={setShowChipNote}
          processor={postProcessor}
          route={route}
          isService={false}
        />
      )}
      {tab === '2' && (
        <DowntimeContainer machineType="post_processor" machineUri={uri} />
      )}
    </Container>
  );
};

PostProcessor.defaultProps = {
  postProcessor: null,
};

PostProcessor.propTypes = {
  postProcessor: postProcessorTypeResourceType,
  handleSelectTab: PropTypes.func.isRequired,
  route: PropTypes.shape({ uuid: PropTypes.string }).isRequired,
  tab: PropTypes.string.isRequired,
};

export default PostProcessor;
