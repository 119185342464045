import React from 'react';
import PropTypes from 'prop-types';
import { ROUTES, FEATURES } from 'rapidfab/constants';
import getRouteURI from 'rapidfab/utils/getRouteURI';
import { Navbar, Nav, NavItem, NavLink, NavDropdown } from 'react-bootstrap';
import Fa from 'react-fontawesome';
import { FormattedMessage } from 'rapidfab/i18n';
import Feature from 'rapidfab/components/Feature';
import NavProfile from 'rapidfab/components/navbar/NavProfile';
import {
  Plan,
  Work,
  Inventory,
  Manage,
} from 'rapidfab/components/navbar/Titles';
import UseNonMfgLanguageFeature from 'rapidfab/components/generalMfgLanguage/UseNonMfgLanguageFeature';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYenSign, faTruckLoading, faShip, faWarehouse } from '@fortawesome/free-solid-svg-icons';

const NavLinksRegular = ({
  currentUser,
  onChangeLocale,
  locale,
  onLogout,
  onImpersonateStop,
  session,
  maEnabled,
  mesEnabled,
  specimenLibraryEnabled,
  serviceProvidersEnabled,
  materialManagementEnabled,
  isPrepWorkflowEnabled,
  isWIPMatrixEnabled,
  isDigitalDesignWarehouseFeatureEnabled,
}) => (
  <Navbar.Collapse>
    {(maEnabled || mesEnabled) && (
      <Nav>
        <NavDropdown
          // eventKey={1}
          title={<Plan />}
          id="uxNavPlan"
        >
          {mesEnabled && (
            <NavDropdown.Item eventKey={1.1} href={getRouteURI(ROUTES.ORDERS)}>
              <Fa name="files-o" />{' '}
              <FormattedMessage id="plan.orders" defaultMessage="Orders" />
            </NavDropdown.Item>
          )}

          <NavDropdown.Item eventKey={1.2} href={getRouteURI(ROUTES.RUNS)}>
            <Fa name="list" />{' '}
            <FormattedMessage id="plan.runs" defaultMessage="Runs" />
          </NavDropdown.Item>

          <NavDropdown.Item eventKey={1.3} href={getRouteURI(ROUTES.PIECES)}>
            <Fa name="wrench" />{' '}
            <FormattedMessage id="pieces" defaultMessage="Pieces" />
          </NavDropdown.Item>

          <Feature featureName={FEATURES.SERVICE_PROVIDER_AT_STEPS}>
            <NavDropdown.Item
              eventKey={1.4}
              href={getRouteURI(ROUTES.SERVICE_PROVIDER_JOBS)}
            >
              <Fa name="clipboard" />{' '}
              <FormattedMessage
                id="plan.serviceProviderJobs"
                defaultMessage="Service Provider Jobs"
              />
            </NavDropdown.Item>
          </Feature>
        </NavDropdown>

        <NavDropdown
          // eventKey={2}
          title={<Work />}
          id="uxNavWork"
        >
          <NavDropdown.Item eventKey={2.1} href={getRouteURI(ROUTES.QUEUES)}>
            <Fa name="list" />{' '}
            <FormattedMessage id="work.queues" defaultMessage="Machine Queues" />
          </NavDropdown.Item>
          <NavDropdown.Item eventKey={2.2} href={`${getRouteURI(ROUTES.ORDERS)}?view_mode=chart`}>
            <Fa name="calendar" />{' '}
            <FormattedMessage id="work.productionGantt" defaultMessage="Production Gantt" />
          </NavDropdown.Item>
          {isWIPMatrixEnabled && (
            <NavDropdown.Item eventKey={2.3} href={`${getRouteURI(ROUTES.WIP_MATRIX)}`}>
              <Fa name="table" />{' '}
              <FormattedMessage id="work.workInProgressMatrix" defaultMessage="WIP Matrix" />
            </NavDropdown.Item>
          )}
          {isPrepWorkflowEnabled && (
            <NavDropdown.Item eventKey={2.4} href={getRouteURI(ROUTES.PREP_TASK_RECORDS)}>
              <Fa name="check-square" />{' '}
              <FormattedMessage id="tasks" defaultMessage="User Tasks" />
            </NavDropdown.Item>
          )}
          <NavDropdown.Divider style={{ display: 'none' }} />
        </NavDropdown>

        <NavDropdown
          // eventKey={3}
          title={<Inventory />}
          id="uxNavInventory"
        >
          {mesEnabled && (
            <NavDropdown.Item eventKey={3.1} href={getRouteURI(ROUTES.STOCKS)}>
              <Fa name="tags" />{' '}
              <FormattedMessage
                id="inventory.materialStocks"
                defaultMessage="Material Stocks"
              />
            </NavDropdown.Item>
          )}
          {mesEnabled && materialManagementEnabled && (
            <NavDropdown.Item
              eventKey={3.2}
              href={getRouteURI(ROUTES.MATERIAL_LOTS)}
            >
              <Fa name="archive" />{' '}
              <FormattedMessage
                id="inventory.materialLots"
                defaultMessage="Material Lots"
              />
            </NavDropdown.Item>
          )}
          {mesEnabled && materialManagementEnabled && (
            <NavDropdown.Item eventKey={3.3} href={getRouteURI(ROUTES.MATERIAL_BATCHES)}>
              <Fa name="cubes" />{' '}
              <FormattedMessage
                id="inventory.materialBatches"
                defaultMessage="Material Batches"
              />
            </NavDropdown.Item>
          )}
          <NavDropdown.Item eventKey={3.4} href={getRouteURI(ROUTES.PRINTERS)}>
            <Fa name="print" />{' '}
            <UseNonMfgLanguageFeature
              mfgLanguageComponent={(
                <FormattedMessage
                  id="inventory.printers"
                  defaultMessage="Printers"
                />
              )}
              nonMfgLanguageComponent={(
                <FormattedMessage
                  id="mfg.printer.productionDevice"
                  defaultMessage="Production Device"
                />
              )}
            />
          </NavDropdown.Item>
          {mesEnabled && (
            <NavDropdown.Item
              eventKey="3.5"
              href={getRouteURI(ROUTES.POST_PROCESSORS)}
            >
              <Fa name="object-ungroup" />{' '}
              <FormattedMessage
                id="inventory.postProcessors"
                defaultMessage="Post Processors"
              />
            </NavDropdown.Item>
          )}
          <Feature featureName={FEATURES.SERVICE_PROVIDER_AT_STEPS}>
            <NavDropdown.Item
              eventKey="3.6"
              href={getRouteURI(ROUTES.POST_PROCESSOR_SERVICES)}
            >
              <Fa name="clipboard" />{' '}
              <FormattedMessage
                id="inventory.postProcessorServices"
                defaultMessage="Post Processor Services"
              />
            </NavDropdown.Item>
          </Feature>
        </NavDropdown>

        <NavDropdown
          // eventKey={4}
          title={<Manage />}
          id="uxNavManage"
        >
          {specimenLibraryEnabled && (
            isDigitalDesignWarehouseFeatureEnabled ? (
              <NavDropdown.Item
                eventKey="4.1"
                href={getRouteURI(ROUTES.DIGITAL_DESIGN_WAREHOUSE)}
              >
                <FontAwesomeIcon icon={faWarehouse} />{' '}
                <p>Digital Design Warehouse</p>
              </NavDropdown.Item>
            ) : (
              <NavDropdown.Item
                eventKey="4.1"
                href={getRouteURI(ROUTES.MODEL_LIBRARY)}
              >
                <Fa name="cube" />{' '}
                <FormattedMessage
                  id="manage.modelLibrary"
                  defaultMessage="Model Library"
                />
              </NavDropdown.Item>
            )
          )}
          <NavDropdown.Item
            eventKey="4.2"
            href={getRouteURI(ROUTES.MANUFACTURERS)}
          >
            <Fa name="industry" />{' '}
            <FormattedMessage
              id="manage.manufacturers"
              defaultMessage="Manufacturers"
            />
          </NavDropdown.Item>

          <NavDropdown.Item eventKey={4.3} href={getRouteURI(ROUTES.LOCATIONS)}>
            <Fa name="map-marker" />{' '}
            <FormattedMessage
              id="manage.locations"
              defaultMessage="Locations"
            />
          </NavDropdown.Item>

          {mesEnabled && serviceProvidersEnabled && (
            <NavDropdown.Item
              eventKey="3.4"
              href={getRouteURI(ROUTES.SERVICE_PROVIDERS)}
            >
              <FontAwesomeIcon icon={faTruckLoading} />{' '}
              <FormattedMessage
                id="manage.serviceProviders"
                defaultMessage="Service Providers"
              />
            </NavDropdown.Item>
          )}

          {mesEnabled && (
            <NavDropdown.Item
              eventKey="4.4"
              href={getRouteURI(ROUTES.SHIPPINGS)}
            >
              <Fa name="truck" />{' '}
              <FormattedMessage
                id="shippingPartners"
                defaultMessage="Shipping Partners"
              />
            </NavDropdown.Item>
          )}

          <Feature
            featureNames={[
              FEATURES.SERVICE_PROVIDER_AT_STEPS,
              FEATURES.SHIPMENT_FOR_ORDER,
            ]}
          >
            <NavDropdown.Item
              eventKey="4.5"
              href={getRouteURI(ROUTES.SHIPMENTS)}
            >
              <FontAwesomeIcon icon={faShip} />{' '}
              <FormattedMessage
                id="manage.shipments"
                defaultMessage="Shipments"
              />
            </NavDropdown.Item>
          </Feature>

          {mesEnabled && (
            <NavDropdown.Item
              eventKey="4.6"
              href={getRouteURI(ROUTES.CONVERSIONS)}
            >
              <FontAwesomeIcon icon={faYenSign} />{' '}
              <FormattedMessage
                id="manage.currency-conversions"
                defaultMessage="Currency Conversions"
              />
            </NavDropdown.Item>
          )}

          <NavDropdown.Divider />

          {mesEnabled && (
            <NavDropdown.Item
              eventKey="4.7"
              href={getRouteURI(ROUTES.MATERIALS)}
            >
              <Fa name="object-group" />{' '}
              <FormattedMessage
                id="manage.materialTypes"
                defaultMessage="Material Types"
              />
            </NavDropdown.Item>
          )}

          <NavDropdown.Item
            eventKey="4.8"
            href={getRouteURI(ROUTES.PRINTER_TYPES)}
          >
            <Fa name="print" />{' '}
            <UseNonMfgLanguageFeature
              mfgLanguageComponent={(
                <FormattedMessage
                  id="manage.printerTypes"
                  defaultMessage="Printer Types"
                />
              )}
              nonMfgLanguageComponent={(
                <FormattedMessage
                  id="mfg.printerTypes.productionDeviceTypes"
                  defaultMessage="Production Device Types"
                />
              )}
            />
          </NavDropdown.Item>

          {mesEnabled && (
            <NavDropdown.Item
              eventKey="4.9"
              href={getRouteURI(ROUTES.POST_PROCESSOR_TYPES)}
            >
              <Fa name="object-group" />{' '}
              <FormattedMessage
                id="manage.postProcessorTypes"
                defaultMessage="Post Processor Types"
              />
            </NavDropdown.Item>
          )}

          {mesEnabled && (
            <NavDropdown.Item
              eventKey="4.10"
              href={getRouteURI(ROUTES.PRODUCTION_WORKFLOW_LIST)}
            >
              <Fa name="list-ol" />{' '}
              <FormattedMessage id="workflows" defaultMessage="Workflows" />
            </NavDropdown.Item>
          )}
        </NavDropdown>

        <Feature featureName={FEATURES.MACHINE_ANALYTICS}>
          <NavItem>
            <NavLink eventKey="5.1" href={getRouteURI(ROUTES.ANALYTICS, {}, {})}>
              Analytics
            </NavLink>
          </NavItem>
        </Feature>

        <Feature featureName={FEATURES.ANATOMICAL_MODEL}>
          <NavItem>
            <NavLink
              eventKey="5.2"
              href={getRouteURI(ROUTES.ANATOMICAL_MODELS, {}, {})}
            >
              Assemblies
            </NavLink>
          </NavItem>
        </Feature>
      </Nav>
    )}

    <NavProfile
      currentUser={currentUser}
      locale={locale}
      onChangeLocale={onChangeLocale}
      onLogout={onLogout}
      onImpersonateStop={onImpersonateStop}
      session={session}
    />
  </Navbar.Collapse>
);

NavLinksRegular.propTypes = {
  currentUser: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  locale: PropTypes.string.isRequired,
  onChangeLocale: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onImpersonateStop: PropTypes.func.isRequired,
  session: PropTypes.shape({}).isRequired,
  maEnabled: PropTypes.bool.isRequired,
  mesEnabled: PropTypes.bool.isRequired,
  specimenLibraryEnabled: PropTypes.bool.isRequired,
  serviceProvidersEnabled: PropTypes.bool.isRequired,
  materialManagementEnabled: PropTypes.bool.isRequired,
  isPrepWorkflowEnabled: PropTypes.bool.isRequired,
  isWIPMatrixEnabled: PropTypes.bool.isRequired,
  isDigitalDesignWarehouseFeatureEnabled: PropTypes.bool.isRequired,
};

export default NavLinksRegular;
