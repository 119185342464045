export const FILE_EXTENSIONS = {
  BREP: 'brep',
  CARBON: 'carbon',
  CATPART: 'catpart',
  CMB: 'cmb',
  CSV: 'csv',
  DAE: 'dae',
  DOC: 'doc',
  DOCX: 'docx',
  DXF: 'dxf',
  EOSJOB: 'eosjob',
  FORM: 'form',
  GCODE: 'gcode',
  IAM: 'iam',
  IGES: 'iges',
  IGS: 'igs',
  IPT: 'ipt',
  JPEG: 'jpeg',
  JPG: 'jpg',
  JT: 'jt',
  J_T: 'j_t',
  MAGICS: 'magics',
  MFP: 'mfp',
  MP3: 'mp3',
  MPF: 'mpf',
  MTT: 'mtt',
  OBJ: 'obj',
  PDF: 'pdf',
  PLY: 'ply',
  PNG: 'png',
  PPT: 'ppt',
  PPTX: 'pptx',
  PRINT: 'print',
  PRT: 'prt',
  SAT: 'sat',
  SLDPRT: 'sldprt',
  SLI: 'sli',
  STEP: 'step',
  STL: 'stl',
  STP: 'stp',
  THREE_DM: '3dm',
  THREE_MF: '3mf',
  TIF: 'tif',
  TIFF: 'tiff',
  XLS: 'xls',
  XLSX: 'xlsx',
  X_T: 'x_t',
  XT: 'xt',
  WRL: 'wrl',
  ADM: 'adm',
};

export const RUN_MODEL_FILE_EXTENSIONS = [FILE_EXTENSIONS.STL, FILE_EXTENSIONS.THREE_MF];

export const ALLOWED_DOCUMENT_EXTENSIONS = [
  FILE_EXTENSIONS.DOC,
  FILE_EXTENSIONS.DOCX,
  FILE_EXTENSIONS.PDF,
  FILE_EXTENSIONS.STL,
  FILE_EXTENSIONS.MP3,
  FILE_EXTENSIONS.DXF,
  FILE_EXTENSIONS.CSV,
  FILE_EXTENSIONS.XLS,
  FILE_EXTENSIONS.XLSX,
  FILE_EXTENSIONS.JPEG,
  FILE_EXTENSIONS.PNG,
  FILE_EXTENSIONS.OBJ,
  FILE_EXTENSIONS.STP,
  FILE_EXTENSIONS.STEP,
  FILE_EXTENSIONS.THREE_MF,
  FILE_EXTENSIONS.DAE,
  FILE_EXTENSIONS.PLY,
  FILE_EXTENSIONS.IGS,
  FILE_EXTENSIONS.BREP,
  FILE_EXTENSIONS.CATPART,
  FILE_EXTENSIONS.CMB,
  FILE_EXTENSIONS.CARBON,
  FILE_EXTENSIONS.FORM,
  FILE_EXTENSIONS.GCODE,
  FILE_EXTENSIONS.MFP,
  FILE_EXTENSIONS.PRINT,
  FILE_EXTENSIONS.PPT,
  FILE_EXTENSIONS.PPTX,
  FILE_EXTENSIONS.PRT,
  FILE_EXTENSIONS.SLDPRT,
  FILE_EXTENSIONS.MPF,
  FILE_EXTENSIONS.JPG,
  FILE_EXTENSIONS.MAGICS,
  FILE_EXTENSIONS.MTT,
  FILE_EXTENSIONS.EOSJOB,
  FILE_EXTENSIONS.SLI,
  FILE_EXTENSIONS.WRL,
  FILE_EXTENSIONS.ADM,
];

export const ALLOWED_MODEL_EXTENSIONS = [FILE_EXTENSIONS.STL];

export const NATIVE_CAD_CONVERSION_EXTENSIONS = [
  FILE_EXTENSIONS.X_T,
  FILE_EXTENSIONS.XT,
  FILE_EXTENSIONS.SAT,
  FILE_EXTENSIONS.STEP,
  FILE_EXTENSIONS.STP,
  FILE_EXTENSIONS.IGS,
  FILE_EXTENSIONS.IGES,
  FILE_EXTENSIONS.SLDPRT,
  FILE_EXTENSIONS.IPT,
  FILE_EXTENSIONS.PRT,
  FILE_EXTENSIONS.CATPART,
  FILE_EXTENSIONS.JT,
  FILE_EXTENSIONS.J_T,
  FILE_EXTENSIONS.THREE_DM,
  FILE_EXTENSIONS.OBJ,
  FILE_EXTENSIONS.IAM,
  FILE_EXTENSIONS.DAE,
];
export const ZVERSE_CONVERSION_EXTENSIONS = [
  FILE_EXTENSIONS.TIF,
  FILE_EXTENSIONS.TIFF,
  FILE_EXTENSIONS.PDF,
  FILE_EXTENSIONS.PNG,
];

export const MIME_TYPES = {
  OCTET_STREAM: 'application/octet-stream',
  JSON: 'application/json',
  STL: 'application/sla',
};
